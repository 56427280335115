<div class="container-fluid basic_table">
  <div class="row">
    <div class="card">
      <div class="card-header">
        <div class="text-end">
          <button
            class="refresh-button me-3"
            [disabled]="isLoading"
            (click)="toggleSocket()"
            [ngbTooltip]="
              isSocketPaused ? 'Reanudar Solicitudes' : 'Pausar Solicitudes'
            "
            tooltipClass="custom-tooltip"
          >
            <i
              [ngClass]="
                isSocketPaused ? 'fa-solid fa-play' : 'fa-solid fa-pause'
              "
            ></i>
          </button>
          <button
            class="refresh-button me-3"
            [disabled]="isLoading"
            ngbTooltip="Recargar Tabla"
            tooltipClass="custom-tooltip"
            (click)="reloadTable()"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          @if (!isMobile) {
          <button
            class="primary-button-sm me-3"
            ngbTooltip="Descargar Información en .xls"
            tooltipClass="custom-tooltip"
            [disabled]="isLoading"
            (click)="exportToExcel()"
          >
            <i class="far fa-file-excel"></i>
          </button>
          } @if (buttonAllowed) {
          <button
            class="primary-button me-3"
            [disabled]="isLoading"
            (click)="openCreateCwFormModal()"
          >
            <i class="fa-solid fa-circle-plus"></i> Nueva Solicitud
          </button>
          }
        </div>
      </div>
      @if (!(isMobile$ | async)) {
      <div class="card-body custom-datatable noscroll">
        <div class="table-responsive signal-table custom-datatable">
          <table class="table table-hover table-responsive">
            <thead class="mx-auto text-center">
              <tr>
                @for (column of columnsTable; track $index) {
                <th>{{ column.name }}</th>
                }
              </tr>
            </thead>
            @if (isLoading) {
            <tbody class="text-center">
              <tr>
                <td colspan="11">
                  <!-- Ajusta el colspan según el número de columnas -->
                  <div class="loader-box">
                    <div class="loader-16"></div>
                  </div>
                </td>
              </tr>
            </tbody>
            } @if (!isLoading) { @for (item of cwData; track item.id) {
            <tbody class="text-center" [id]="'row-' + item.id">
              <tr>
                <td class="mx-auto text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    @if (getButtons(item).length > 0) {

                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div
                        ngbDropdown
                        (openChange)="onDropdownToggle($event)"
                        class="btn-group"
                        role="group"
                      >
                        @if ([TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
                        TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
                        TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT
                        ].includes(item.tipo_transaccion) &&
                        [STATE_NAMES.PENDING, ].includes(item.estado)) {
                        <span
                          class="img-dot"
                          ngbTooltip="Ver Transacción"
                          tooltipClass="custom-tooltip"
                          (click)="openViewModal(item.id)"
                          ><i class="fa-solid fa-eye fa-2xs text-success"></i
                        ></span>
                        }@else if (item.foto_comprobante &&
                        item.foto_comprobante != '' && item.foto_comprobante !=
                        'null' ) {
                        <span
                          class="img-dot"
                          ngbTooltip="Ver Imagen"
                          tooltipClass="custom-tooltip"
                          (click)="openImageModal(item.id)"
                          ><i class="fa-solid fa-image fa-2xs text-success"></i
                        ></span>
                        }
                        <button
                          type="button"
                          class="btn btn-light"
                          id="dropdownBasic1"
                          ngbTooltip="Más"
                          tooltipClass="custom-tooltip"
                          ngbDropdownToggle
                          (click)="onMoreButtonClick(item)"
                        >
                          <i class="fas fa-plus"></i>
                          @if (item.detalle && item.detalle != 'null' &&
                          item.detalle != 'Success') {
                          <span class="notification-dot"></span>
                          }
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <div class="row mx-auto pb-2">
                            @for (btn of getButtons(item); track $index) {
                            <div class="col-4">
                              <button
                                class="btn btn-sm btn-mode mt-2"
                                [ngClass]="btn.btnClass"
                                ngbTooltip="{{ btn.tooltip }}"
                                tooltipClass="custom-tooltip"
                                (click)="btn.action()"
                              >
                                <i class="fas" [ngClass]="btn.icon"></i>
                              </button>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                      @if (item.updated_by_initials) {
                      <button
                        class="btn btn-sm label-user btn-mode"
                        ngbTooltip="{{ item.updated_by | lowercase }}"
                        tooltipClass="custom-tooltip"
                        type="button"
                      >
                        {{ item.updated_by_initials }}
                      </button>
                      }
                    </div>
                    }@else { No hay Acciones Disponibles }
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div
                    class="d-flex justify-content-center align-items-center position-relative"
                  >
                    <div
                      class="d-flex flex-column align-items-center position-relative"
                    >
                      <i
                        *ngIf="item.estado_tiempo_espera"
                        class="fas fa-business-time fa-sm status-icon-time-out"
                        ngbTooltip="La boleta demorará 20 min"
                        tooltipClass="custom-tooltip"
                      >
                      </i>
                      <span
                        *ngIf="item.estado_revisar_boleta === 1"
                        ngbTooltip="Revisar Boleta"
                        tooltipClass="custom-tooltip"
                        class="status-dot status-dot-check-ticket"
                        [ngClass]="{
                          'status-dot-active': item.estado_revisar_boleta === 1
                        }"
                      >
                      </span>
                    </div>
                    <span
                      [ngClass]="getStateClasses(item.estado).classes"
                      [innerHTML]="getStateClasses(item.estado).text"
                      class="status-span"
                    ></span>
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    <strong>{{ item.monto }}</strong>
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div
                    class="d-flex justify-content-center align-items-center ms-4"
                    [ngClass]="getTransactionClass(item.tipo_transaccion)"
                  >
                    <i
                      [ngClass]="getTransactionIconClass(item.tipo_transaccion)"
                      class="me-2"
                    ></i>
                    {{ transactionTypes[item.tipo_transaccion].label }}
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="d-flex flex-column align-items-start ms-4">
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Fecha de creación de solicitud"
                        tooltipClass="custom-tooltip"
                      >
                        <i
                          class="fas fa-calendar-week informative-icon fa-sm me-2"
                        ></i>
                        <span>{{ item.creado | date : "dd/MM/yyyy" }}</span>
                      </div>
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Hora de creación de solicitud"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-clock fa-sm me-2 informative-icon"></i>
                        <span>{{ item.creado | date : "HH:mm:ss" }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                @if (!isMobile) {
                <td class="mx-auto text-center">
                  <div class="d-flex">
                    <div class="d-flex flex-column ms-4">
                      @if(item.id_jugador !== 0 && item.id_jugador !== null){
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Jugador"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-futbol fa-sm me-2 sorti-2"></i>
                        <span>{{ item.player }}</span>
                      </div>
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Agente"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-user-tie fa-sm me-2 sorti-2"></i>
                        <span>{{ item.agent }}</span>
                      </div>
                      }@else if(item.agent !== 0 && item.agent !== null){
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Agente"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-user-tie fa-sm me-2 sorti-2"></i>
                        <span>{{ item.agent }}</span>
                      </div>
                      }@else if (item.accounting_account !== 0 &&
                      item.accounting_account !== null) {
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Cuenta Contable"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-suitcase fa-sm me-2 sorti-2"></i>
                        <span>{{ item.accounting_account.nombre_cuenta }}</span>
                      </div>
                      }
                    </div>
                  </div>
                </td>
                <td class="mx-auto text-center">
                  @if ((item.comprobante && item.comprobante !== 'null') ||
                  (item.comprobante_empresa && item.comprobante_empresa !==
                  'null')) {
                  <div class="d-flex flex-column ms-4">
                    @if (item.comprobante && item.comprobante !== 'null') {
                    <div
                      class="d-flex align-items-center me-4 mb-2"
                      ngbTooltip="Comprobante Cliente"
                      tooltipClass="custom-tooltip"
                    >
                      <i class="fas fa-futbol fa-sm me-2 client"></i>
                      <span>{{ item.comprobante }}</span>
                    </div>
                    } @if (item.comprobante_empresa && item.comprobante_empresa
                    !== 'null') {
                    <div
                      class="d-flex align-items-center me-4 mb-2"
                      ngbTooltip="Comprobante Empresa"
                      tooltipClass="custom-tooltip"
                    >
                      <i class="fas fa-user-tie fa-sm me-2 sorti"></i>
                      <span>{{ item.comprobante_empresa }}</span>
                    </div>
                    }
                  </div>
                  } @else {
                  <span>S/C</span>
                  }
                </td>

                }
                <td class="mx-auto text-start">
                  <div class="d-flex flex-column ms-4">
                    @if([TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT].includes(item.tipo_transaccion)){
                    <div class="align-items-start">
                      <span
                        >Los tipos de transacción de tipo
                        <strong>Crédito Depósito</strong> no tienen datos
                        bancarios</span
                      >
                    </div>
                    }@if([TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL].includes(item.tipo_transaccion)){
                    <div class="align-items-start">
                      <span
                        >Los tipos de transacción de tipo
                        <strong>Crédito Retiro</strong> no tienen datos
                        bancarios</span
                      >
                    </div>
                    }@else { @if (item.identificacion && item.identificacion !=
                    'null' && item.identificacion != 'undefined') {
                    <div class="d-flex align-items-center me-4 mb-2">
                      <i class="fas fa-id-card fa-sm me-2 sorti-2"></i>
                      <span>{{ item.identificacion }}</span>
                    </div>
                    } @if (item.numero_cuenta && item.numero_cuenta != 'null' &&
                    item.numero_cuenta != 'undefined') {
                    <div class="d-flex align-items-center me-4 mb-2">
                      <i class="fa-solid fa-credit-card fa-sm me-2 sorti-2"></i>
                      <span>{{ item.numero_cuenta }}</span>
                    </div>
                    } @if (item.nombre_titular && item.nombre_titular != 'null'
                    && item.nombre_titular != 'undefined') {
                    <div class="d-flex align-items-center me-4 mb-2">
                      <i class="fas fa-user fa-sm me-2 sorti-2"></i>
                      <span>{{ item.formatted_name }}</span>
                    </div>
                    } @if (item.id_banco && item.id_banco != 'null'&&
                    item.id_banco != 'undefined') {
                    <div class="d-flex align-items-center me-4">
                      <i class="fas fa-building-columns fa-sm me-2 sorti-2"></i>
                      <span>{{ item.bank }}</span>
                    </div>
                    } }
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div class="d-flex flex-column ms-4">
                    @if (item.id_banco_empresa) {
                    <div class="d-flex align-items-center me-4">
                      <i class="fas fa-building-columns fa-sm me-2 sorti-2"></i>
                      <span>{{ item.bank_enterprise }}</span>
                    </div>
                    }@else {
                    <span>Sin Datos Bancarios</span>
                    }@if(item.holder){
                    <div class="d-flex align-items-center me-4 mb-2 mt-1">
                      <i
                        class="fas fa-id-badge fa-sm me-2 sorti-2"
                        style="padding-left: 3px"
                      ></i>
                      <span>{{ item.formatted_holder_name }}</span>
                    </div>
                    }
                  </div>
                </td>
                @if (!isMobile) {
                <td class="mx-auto text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="d-flex flex-column align-items-start ms-4">
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Fecha de actualización de solicitud"
                        tooltipClass="custom-tooltip"
                      >
                        <i
                          class="fas fa-calendar-week fa-sm me-2 informative-icon"
                        ></i>
                        <span>{{
                          item.actualizado | date : "dd/MM/yyyy"
                        }}</span>
                      </div>
                      <div
                        class="d-flex align-items-center me-4 mb-2"
                        ngbTooltip="Hora de actualización de solicitud"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fas fa-clock fa-sm me-2 informative-icon"></i>
                        <span>{{ item.actualizado | date : "HH:mm:ss" }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    {{ item.created_by }}
                  </div>
                </td>
                }
              </tr>
            </tbody>
            } }
          </table>
          @if(isLoading){
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
          </div>
          } @else {
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <div class="total-monto-container mt-3">
              Total en Dólares: <strong>{{ totalDolares }}</strong>
            </div>
            <div class="total-monto-container text-end mt-3">
              Total en Soles: <strong>{{ totalSoles }}</strong>
            </div>
          </div>
          }
        </div>
      </div>
      }@else {
      <div class="card-body mt-2">
        @if (isLoading) {
        <div class="loader-box">
          <div class="loader-16"></div>
        </div>
        }@else {@for (item of cwData; track $index ) {
        <div class="card mb-3">
          <div class="card-body">
            <div
              class="card-more-button-holder"
              (click)="toggleMenu($index, item)"
            >
              <i class="fa-solid fa-ellipsis"></i>
            </div>
            <div class="card-c-holder">
              @for (column of columnsTable; track $index) { @if (column.value) {
              <div class="geneKeyValue card-list-col">
                <p
                  role="heading"
                  aria-level="1"
                  class="geneKeyValue__label ellipsis-text"
                >
                  {{ column.name }}
                </p>
                <br />
                @switch (column.value) { @case ('estado') {

                <div class="geneKeyValue__value ellipsis-text">
                  <i
                    *ngIf="item.estado_tiempo_espera"
                    class="fas fa-business-time fa-sm status-icon-time-out"
                    ngbTooltip="La boleta demorará 20 min"
                    tooltipClass="custom-tooltip"
                  >
                  </i>

                  <span
                    *ngIf="
                      (item.estado_revisar_boleta === 1 &&
                        (item.jugador_white_list?.lista_negra === 1 ||
                          item.agente_white_list?.lista_negra === 1)) ||
                      item.jugador_white_list?.lista_negra === 1 ||
                      item.agente_white_list?.lista_negra === 1
                    "
                    ngbTooltip="Usuario en Lista Negra"
                    tooltipClass="custom-tooltip"
                    class="status-dot status-dot-black-list"
                    [ngClass]="{
                      'status-dot-active':
                        item.jugador_white_list?.lista_negra === 1 ||
                        item.agente_white_list?.lista_negra === 1
                    }"
                  >
                  </span>

                  <span
                    *ngIf="item.estado_revisar_boleta === 1"
                    ngbTooltip="Revisar Boleta"
                    tooltipClass="custom-tooltip"
                    class="status-dot status-dot-check-ticket"
                    [ngClass]="{
                      'status-dot-active': item.estado_revisar_boleta === 1
                    }"
                  >
                  </span>
                </div>
                <p class="geneKeyValue__value ellipsis-text">
                  <span
                    [ngClass]="getStateClasses(item.estado).classes"
                    [innerHTML]="getStateClasses(item.estado).text"
                  ></span>
                </p>
                }@case ('tipo_transaccion') {
                <p
                  class="geneKeyValue__value ellipsis-text"
                  [ngClass]="getTransactionClass(item.tipo_transaccion)"
                >
                  <i
                    [ngClass]="getTransactionIconClass(item.tipo_transaccion)"
                    class="me-2"
                  ></i>
                  {{ transactionTypes[item.tipo_transaccion].label }}
                </p>
                }@case ('creado') {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item.creado | date : "dd/MM/yyyy" }}
                </p>
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item.creado | date : "HH:mm:ss" }}
                </p>
                } @default {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value] }}
                </p>
                } }
              </div>
              } }
            </div>
          </div>
        </div>
        }}
      </div>
      }
      <div class="card-footer">
        <div class="row mt-3 mb-3">
          <shared-custom-pagination
            [collectionSize]="collectionSize"
            (pageChange)="onPageChange($event)"
            (pageSizeChange)="onLimitChange($event)"
          ></shared-custom-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="overlay"
  *ngIf="showMenu || showSubMenu"
  (click)="closeMenu()"
></div>
<div class="popover1" *ngIf="showMenu">
  <ul>
    <li
      (click)="openSubMenu('client')"
      style="border-top: 1px solid var(--chart-border)"
    >
      <i class="fas fa-user informative-icon me-2"></i> Ver Detalle Cliente
    </li>
    <li
      (click)="openSubMenu('enterprise')"
      style="border-top: 1px solid var(--chart-border)"
    >
      <i class="fas fa-building informative-icon me-2"></i> Ver Detalle Empresa
    </li>
    <li
      *ngFor="let button of buttons"
      (click)="button.action()"
      style="border-top: 1px solid var(--chart-border)"
    >
      <i class="fas informative-icon me-2" [ngClass]="button.icon"></i>
      {{ button.tooltip }}
    </li>
    <li (click)="closeMenu()" style="border-top: 1px solid var(--chart-border)">
      <i class="fas fa-times informative-icon me-2"></i> Close
    </li>
  </ul>
</div>
<div class="popover1" *ngIf="showSubMenu">
  <div class="submenu">
    <ul>
      <li (click)="closeSubMenu()">
        <i class="fas fa-reply informative-icon me-2"></i> Regresar
      </li>
    </ul>
    <div class="submenu-content">
      @for (column of actualItem; track $index) {
      <div class="row" style="max-width: 98%">
        <div class="col-6 ellipsis-text" style="font-weight: bold">
          <p>{{ column.key }}:</p>
        </div>
        <div class="col-6 ellipsis-text">
          {{ column.value }}
        </div>
      </div>
      }
    </div>
  </div>
</div>
