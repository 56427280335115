import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import {
  ACTION_SOCKET,
  CONFIG_BASE_DATE_RANGEPICKER,
} from '../../../../../../core/constants/constants';
import { ChargesWithdrawalsRequestService } from '../../../../../../core/services/charges-withdrawals-request.service';
import { DynamicModalService } from '../../../../../../core/services/dynamic-modal.service';
import { FilterCommunicationService } from '../../../../../../core/services/filter-communication.service';
import { SocketService } from '../../../../../../core/services/socket.service';
import { selectAuth } from '../../../../../../core/state/auth/auth.selector';
import { selectScreen } from '../../../../../../core/state/screen/screen.selectors';
import { formatAmount } from '../../../../../../core/utils/format-numeric.utils';
import {
  STATE_CLASSES,
  TRANSACTION_CLASSES,
  TRANSACTION_ICON_CLASSES,
  columnsTable,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_VALUES,
  STATE_NAMES,
  COUNTRIES,
  DEFAULT_VALUE,
  DATE_TEXT,
  CURRENCY_VALUES,
  headersExcel,
} from '../../../../../../shared/data/suscriptions-payments/constants';
import { DivideCwRequestComponent } from '../forms/divide-cw-request/divide-cw-request.component';
import { EditCwRequestComponent } from '../forms/edit-cw-request/edit-cw-request.component';
import { ReverseCwRequestComponent } from '../forms/reverse-cw-request/reverse-cw-request.component';
import { SelectCountryCwComponent } from '../forms/select-country-cw/select-country-cw.component';
import { ImageViewComponent } from '../image-view/image-view.component';
import { UserService } from '../../../../../../core/services/user.service';
import {
  MAIN_PERMISSIONS,
  ROLES,
} from '../../../../../../shared/data/config/constants';
import {
  REQUEST_STATUS,
  REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS,
} from '../../../../../../shared/data/config/constants-buttons';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { ViewPayOutDetailComponent } from '../forms/view-pay-out-detail/view-pay-out-detail.component';
import { itemUpdateCw } from '../../../../../../core/interfaces/subscription-payment.interface';
import { ViewPayComponent } from '../forms/view-pay/view-pay.component';
import { CreateCwRequestComponent } from '../forms/create-cw-request/create-cw-request.component';
import { ExcelExportService } from '../../../../../../shared/services/excel-export.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-cw-table',
  templateUrl: './cw-table.component.html',
  styleUrl: './cw-table.component.scss',
})
export class CwTableComponent implements OnInit {
  @Output() totalPendingChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalProcessedChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalChargeNotIncomeChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalBotProblemChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalCorrectionChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalReverseChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalCountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  public columnsTable = columnsTable;
  public isLoading: boolean = true;
  public cwData: any;
  public transactionTypes = TRANSACTION_TYPES;
  public pendingCount: number = 0;
  public processedCount: number = 0;
  public chargeNotIncomeCount: number = 0;
  public botProblemCount: number = 0;
  public totalCount: number = 0;
  public totalPagesVisible: any;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public rol!: Number;
  messageSubscription: Subscription;
  private filtersSubscription!: Subscription;
  dataSocket: { action: string; data: any } = { action: '', data: {} };
  public collectionSize: number = 0;
  public page: number = 1;
  public limit: number = 10;
  public roles = ROLES;
  public item!: itemUpdateCw;
  public country!: Number;

  public selectedFilter: any;
  public defaultFilter: any;
  public subscribe: Subscription = new Subscription();
  public TRANSACTION_TYPE_VALUES = TRANSACTION_TYPE_VALUES;
  public STATE_NAMES = STATE_NAMES;

  public startDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[0];
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];
  public totalDolares: string = '';
  public totalSoles: string = '';
  public correctionUsed: { [key: string]: boolean } = {};

  public isMobile: boolean = false;
  public token!: string;

  public buttonAllowed: boolean = false;
  public buttonFastViewAllowed: boolean = false;
  public buttonDownloadExcelAllowed: boolean = false;
  public isSocketPaused: boolean = false;
  public pendingUpdates: any[] = [];

  // REDUX
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;

  // CARD
  public actualIndex!: number;
  public actualItemClient: any;
  public actualItemEnterprise: any;
  public cardExpanded: boolean[] = [];
  public subMenuItem: any;
  public actualItem!: any[];
  public itemType!: string;
  public buttons: any[] = [];

  constructor(
    private cwService: ChargesWithdrawalsRequestService,
    private dynamicModal: DynamicModalService,
    private socketService: SocketService,
    private _toastrSvc: ToastrService,
    private store: Store,
    private _filterCommunicationService: FilterCommunicationService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _userService: UserService,
    private alertService: AlertService,
    private excelExportService: ExcelExportService
  ) {
    this.socketService.joinRoom('cargas_retiros_solicitud');
    this.messageSubscription = this.socketService
      .getMessageRoomSubject()
      .subscribe((msg) => {
        if (this.isSocketPaused) {
          this.pendingUpdates.push(msg);
        } else {
          this.processSocketData(msg);
        }
      });
  }
  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
    this.columnsTable = this.isMobile
      ? columnsTable.filter(
          (column) =>
            !['Cliente', 'Comprobante', 'Actualizado', 'Creado Por'].includes(
              column.name
            )
        )
      : columnsTable;
    if (this.isMobile) {
      await this.loadTokenLogged();
    } else {
      await this.loadProfileLogged();
    }
    this.getDataFromFilter();
  }

  ngOnDestroy(): void {
    this.socketService.leaveRoom('cargas_retiros_solicitud');
    this.subscribe.unsubscribe();
  }

  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            this.rol = res.id_rol;
            this.country = res.id_pais;
            this.mainButton();
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  async loadTokenLogged() {
    await new Promise<void>((resolve, reject) => {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
        this._userService.getUserByTokenApp(this.token).subscribe({
          next: (res) => {
            if (res.data.idusuario) {
              this.profile = res.data;
              this.rol = this.profile.id_rol;
              this.country = this.profile.id_pais;
              this.mainButton();
              resolve();
            } else {
              reject(new Error('No user id found'));
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    });
  }

  getStateClasses(state: number) {
    return STATE_CLASSES[state] || { text: '', classes: {} };
  }

  toggleSocket(): void {
    this.isSocketPaused = !this.isSocketPaused;

    if (this.isSocketPaused) {
      this._toastrSvc.info('Las solicitudes han sido pausadas.');
    } else {
      this._toastrSvc.info('Las solicitudes han sido reanudadas.');
      if (this.pendingUpdates.length > DEFAULT_VALUE) {
        this.pendingUpdates.forEach((update) => {
          this.processSocketData(update);
        });
        this.pendingUpdates = [];
      }
    }
  }

  onDropdownToggle(isOpen: boolean): void {
    if (isOpen) {
      this.isSocketPaused = true;
      this._toastrSvc.info('Las solicitudes han sido pausadas.');
    } else {
      this.isSocketPaused = false;
      this._toastrSvc.info('Las solicitudes han sido reanudadas.');
      if (this.pendingUpdates.length > DEFAULT_VALUE) {
        this.pendingUpdates.forEach((update) => {
          this.processSocketData(update);
        });
        this.pendingUpdates = [];
      }
    }
  }

  processSocketData(msg: any): void {
    if (
      msg?.action === ACTION_SOCKET.UPDATE ||
      msg?.action === ACTION_SOCKET.ADD
    ) {
      msg.data.forEach((item: any) => {
        this.updateItemList(item, msg.action);
      });
    }
  }

  getTransactionIconClass(type: number): string {
    return TRANSACTION_ICON_CLASSES[type] || '';
  }

  getTransactionClass(type: number): string {
    return TRANSACTION_CLASSES[type] || '';
  }

  onMoreButtonClick(item: itemUpdateCw): void {
    const montoOriginal = item.monto!.trim();
    const montoConFormato = montoOriginal
      .replace(/[$S/]/g, '')
      .replace(/−/g, '-')
      .replace(/\s/g, '');

    const montoSinMoneda = parseFloat(montoConFormato.replace(',', '.'));
    const formattedName = this.formatName(item.nombre_titular);
    const cleanedItem = {
      ...item,
      monto: montoSinMoneda,
      formatted_name: formattedName,
    };
    this.cwService.setRequest(cleanedItem).subscribe({
      next: (res) => {
        this._toastrSvc.success('Solicitud Asignada a ' + this.profile.usuario);
        this.updateItemList({
          ...cleanedItem,
          updated_by: res.data.updated_by,
        });
      },
      error: () => {
        this._toastrSvc.error('Error al asignar el usuario');
      },
    });
  }

  getData() {
    this.startLoading();
    this.defaultFilter = this.selectedFilter || this.getDefaultFilter();
    this.cwService
      .getAllData(this.page, this.limit, this.defaultFilter)
      .subscribe((res) => {
        this.processResponse(res.data);
        this.stopLoading();
      });
  }

  getDefaultFilter() {
    return this.selectedFilter
      ? this.selectedFilter
      : {
          id_rol: '0',
          date_star: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          date_end: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        };
  }

  formatTotals(totals: any) {
    this.totalDolares = formatAmount(Number(totals.ecuador), 'EC');
    this.totalSoles = formatAmount(Number(totals.peru), 'PE');
  }

  processResponse(response: any) {
    this.formatTotals(response.totals);
    this.cwData = response.data.map((item: any) => this.formatCwData(item));
    this.collectionSize = response.totalCount;
    this.emitChanges(response.counts, response.totalCount);
  }

  emitChanges(counts: any, totalCount: number) {
    this.totalPendingChange.emit(counts.pendiente);
    this.totalProcessedChange.emit(counts.procesado);
    this.totalChargeNotIncomeChange.emit(counts.cargadoNoIngresado);
    this.totalBotProblemChange.emit(counts.problemaBot);
    this.totalCorrectionChange.emit(counts.correcion);
    this.totalReverseChange.emit(counts.reverse);
    this.totalCountChange.emit(totalCount);
  }

  startLoading() {
    this.isLoading = true;
    this.loading.emit(true);
  }

  stopLoading() {
    this.isLoading = false;
    this.loading.emit(false);
  }

  reloadTable() {
    this.isLoading = true;
    this.getData();
  }

  updateItemList(newItem: any, typeAction = ACTION_SOCKET.ADD): void {
    newItem = this.formatCwData(newItem);
    const filtersApplied = this.checkIfOtherFiltersApplied();

    if (this.selectedFilter.updated_by) {
      if (
        newItem.id_usuario_set &&
        newItem.id_usuario_set !== this.profile.idusuario
      ) {
        const index = this.cwData.findIndex(
          (item: any) => item.id === newItem.id
        );
        if (index !== -1) {
          this.cwData.splice(index, 1);
          return;
        }
      }
      if (newItem.id_usuario_set === this.profile.idusuario) {
        const index = this.cwData.findIndex(
          (item: any) => item.id === newItem.id
        );
        if (index === -1) {
          if (this.doesItemMatchFilters(newItem)) {
            if (this.page === 1) {
              this.cwData.unshift(newItem);
            }
          }
        }
        return;
      }
    }

    const index = this.cwData?.findIndex((item: any) => item.id === newItem.id);

    if (index !== -1) {
      Object.keys(newItem).forEach((key) => {
        if (this.cwData[index][key] !== newItem[key]) {
          this.cwData[index][key] = newItem[key];
        }
      });
    } else {
      if (typeAction === ACTION_SOCKET.ADD) {
        if (this.doesItemMatchFilters(newItem)) {
          if (this.page === 1) {
            this.cwData.unshift(newItem);
          }
        }
      }
    }
  }

  doesItemMatchFilters(item: any): boolean {
    const filters = this.selectedFilter;

    const conditions = [
      { condition: filters.receipt, value: item.comprobante },
      {
        condition: filters.amount,
        value: item.monto.replace(/[^0-9.-]+/g, ''),
      }, // Limpiar el monto para obtener el número
      { condition: filters.id_agente, value: item.id_agente },
      { condition: filters.id_jugador, value: item.id_jugador },
      {
        condition: filters.interbank_transfer,
        value: item.transferencia_interbancaria,
      },
      { condition: filters.transaction_type, value: item.tipo_transaccion },
      { condition: filters.state, value: item.estado },
      { condition: filters.bank, value: item.id_banco },
      { condition: filters.identification, value: item.identificacion },
      { condition: filters.beneficiary, value: item.beneficiario },
      { condition: filters.account_number, value: item.numero_cuenta },
      { condition: filters.created_by, value: item.id_usuario_add },
      { condition: filters.type_user, value: item.tipo_usuario },
      { condition: filters.holder, value: item.id_titular },
      { condition: filters.country, value: item.id_pais },
    ];

    for (const { condition, value } of conditions) {
      if (condition) {
        const numericCondition = !isNaN(condition)
          ? Number(condition)
          : condition; // Convertir condition a número si es numérico
        const numericValue = !isNaN(value) ? Number(value) : value; // Convertir value a número si es numérico
        if (numericCondition !== numericValue) {
          return false;
        }
      }
    }

    const createdDate = new Date(item.actualizado);
    const filterStartDate = filters.date_star_update
      ? new Date(filters.date_star_update)
      : null;
    const filterEndDate = filters.date_end_update
      ? new Date(filters.date_end_update)
      : null;

    if (
      (filterStartDate && createdDate < filterStartDate) ||
      (filterEndDate && createdDate > filterEndDate)
    ) {
      return false;
    }

    return true;
  }

  checkIfOtherFiltersApplied(): boolean {
    return Object.keys(this.selectedFilter).some(
      (filter) =>
        filter !== DATE_TEXT.DATE_START &&
        filter != DATE_TEXT.DATE_END &&
        this.selectedFilter[filter]
    );
  }

  //FORMATTING DATA
  formatCwData(item: any) {
    const monto = this.formatAmountWithCountry(item.monto, item.id_pais);

    const formattedName = this.formatName(item.nombre_titular);
    const formattedHolderName = this.formatName(item.holder);
    const initials = this.getInitials(item.updated_by);
    return {
      ...item,
      monto: monto,
      updated_by_initials: initials.toUpperCase(),
      formatted_name: formattedName,
      formatted_holder_name: formattedHolderName,
    };
  }

  formatName(name?: string): string {
    const nameParts = name ? name.split(' ') : [];
    const firstName = nameParts.length > 0 ? nameParts[0].toLowerCase() : '';
    const lastName = nameParts.length > 1 ? nameParts[1].toLowerCase() : '';
    const formattedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const formattedLastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1);
    return `${formattedFirstName} ${formattedLastName}`.trim();
  }

  getInitials(name: string): string {
    const nameParts = name ? name.split(' ') : [];
    return nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : nameParts.length > 0
      ? `${nameParts[0][0]}`
      : '';
  }

  formatAmountWithCountry(amount: number, countryId: number): string {
    return formatAmount(
      Number(amount),
      countryId === CURRENCY_VALUES.ECU ? 'EC' : 'PE'
    );
  }

  //PAGINATION AND FILTER

  onLimitChange(limit: number) {
    this.limit = limit;
    this.getData();
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getData();
  }

  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        if (filter) {
          if (this.isMobile) {
            this.selectedFilter = filter;
            this.getData();
          } else {
            this.selectedFilter = filter;
            this.getData();
          }
        }
      }
    );
  }

  // MODALS
  openImageModal(cwId: any) {
    this.dynamicModal.openModal(
      ImageViewComponent,
      'Comprobante',
      'md',
      true,
      cwId
    );
  }

  openViewModal(cwId: any) {
    this.dynamicModal.openModal(
      ViewPayComponent,
      'Transacción',
      'xl',
      true,
      cwId
    );
  }

  openCreateCwFormModal() {
    if (this.rol === ROLES.AGENT) {
      this.dynamicModal.openModal(
        CreateCwRequestComponent,
        'Agregar Solicitud de Cargas o Retiros ',
        'xl',
        false,
        this.country
      );
    } else {
      this.dynamicModal.openModal(
        SelectCountryCwComponent,
        'Agregar Solicitud de Cargas o Retiros',
        'md',
        true
      );
    }
  }

  openDetailAlert(item: any) {
    Swal.fire({
      title: `<strong>Detalle de la Solicitud</strong>`,
      icon: 'info',
      html: `
      <div>
        <br> ${item.detalle || 'N/A'}
      </div>
    `,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      iconColor: '#8C98A1',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendido',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      customClass: {
        popup: 'my-swal-popup',
      },
    });
  }

  openReverseCw(id: any, estado: any, country: number) {
    this.dynamicModal.openModal(
      ReverseCwRequestComponent,
      'Reverso de Solicitud de Cargas o Retiros',
      'xl',
      false,
      { id, estado, country }
    );
  }

  openEditCw(id: any, estado: any) {
    this.dynamicModal.openModal(
      EditCwRequestComponent,
      'Edición de Solicitud de Cargas o Retiros',
      'xl',
      false,
      { id, estado }
    );
  }

  openDivideCw(id: any) {
    this.dynamicModal.openModal(
      DivideCwRequestComponent,
      'División de Transacción',
      'md',
      false,
      id
    );
  }

  openPayOutCw(id: number) {
    this.dynamicModal.openModal(
      ViewPayOutDetailComponent,
      'Detalles de pago',
      'md',
      false,
      id
    );
  }

  // ALERTS
  setState(id: number, newState: number, idusuarioSet: number) {
    // Utiliza la alerta de confirmación
    this.alertService
      .showConfirmAlert(
        '¿Estás seguro?',
        'No podrás revertir esto!',
        'Sí, cambiar estado!',
        'Cancelar'
      )
      .then((result) => {
        if (result.isConfirmed) {
          if (
            newState === STATE_NAMES.REJECTED ||
            newState === STATE_NAMES.CORRECTION
          ) {
            this.alertService
              .showInputAlert(
                'Escribe el motivo del rechazo',
                'Motivo',
                'Escribe aquí...',
                'Enviar motivo',
                'Cancelar'
              )
              .then((textResult) => {
                if (textResult.isConfirmed && textResult.value) {
                  this.updateStateWithReason(
                    id,
                    newState,
                    idusuarioSet,
                    textResult.value
                  );
                }
              });
          } else {
            this.updateState(id, newState, idusuarioSet);
          }
        }
      });
  }

  updateState(id: number, newState: number, idusuarioSet: number) {
    this.cwService.setState(id, newState, idusuarioSet).subscribe(
      (response) => {
        this.alertService.showSuccessAlert(
          'Se cambió el estado!',
          'La solicitud cambió su estado.'
        );
      },
      (error) => {
        this._toastrSvc.error('Error al actualizar el estado');
      }
    );
  }

  updateStateWithReason(
    id: number,
    newState: number,
    idusuarioSet: number,
    reason: string
  ) {
    this.cwService.setState(id, newState, idusuarioSet, reason).subscribe(
      (response) => {
        this.alertService.showSuccessAlert(
          'Se cambió el estado!',
          'La solicitud cambió su estado.'
        );
      },
      (error) => {
        this._toastrSvc.error('Error al actualizar el estado');
      }
    );
  }

  // BUTTONS
  mainButton() {
    const mainPermission = this.profile?.rol?.permisos_principales;
    const subscriptionsPayments = mainPermission.find(
      (permission: { id: number }) =>
        permission.id === MAIN_PERMISSIONS['Abonos y Pagos']
    );

    if (subscriptionsPayments) {
      this.buttonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.NEW_REQUEST
      );
      this.buttonFastViewAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.FAST_VIEW
      );
      this.buttonDownloadExcelAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_EXCEL
      );
    }
  }

  createButton(
    tooltip: string,
    icon: string,
    btnClass: string,
    action: () => void,
    item?: any
  ) {
    return { tooltip, icon, btnClass, action, item };
  }

  hasPermission(permissionId: number): boolean {
    return this.profile?.rol?.permisos_principales.some(
      (permisoPrincipal: any) =>
        permisoPrincipal.permisos.some(
          (permiso: any) => permiso.id === permissionId
        )
    );
  }

  getButtons(item: any) {
    const buttons = [];
    const permissions = REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS;

    switch (item.estado) {
      case REQUEST_STATUS.PENDING:
        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.CORRECT_REQUEST
          )
        ) {
          buttons.push(
            this.createButton(
              'Corregir Solicitud',
              'fa-eraser',
              'btn-success',
              () => this.setCorrectionUsed(item),
              item
            )
          );
        }
        if (this.hasPermission(permissions.REJECT_REQUEST)) {
          buttons.push(
            this.createButton('Rechazar', 'fa-ban', 'btn-danger', () =>
              this.setState(
                item.id,
                STATE_NAMES.REJECTED,
                this.profile.idusuario
              )
            )
          );
        }
        if (this.hasPermission(permissions.EDIT_REQUEST)) {
          buttons.push(
            this.createButton('Editar', 'fa-pen', 'btn-success', () =>
              this.openEditCw(item.id, item.estado)
            )
          );
        }
        if (
          [
            TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
            TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
            TRANSACTION_TYPE_VALUES.FINANCIAL,
            TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
            TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
          ].includes(item.tipo_transaccion)
        ) {
          if (this.hasPermission(permissions.PREVALIDATED_PROCESSED)) {
            buttons.push(
              this.createButton(
                'Procesado Prevalidado',
                'fa-check-double',
                'btn-success',
                () =>
                  this.setState(
                    item.id,
                    STATE_NAMES.PREVALIDATED_PROCESSED,
                    this.profile.idusuario
                  )
              )
            );
          }
          if (
            [
              TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
              TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
              TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
              TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
            ].includes(item.tipo_transaccion) &&
            this.hasPermission(permissions.PROCESSED)
          ) {
            buttons.push(
              this.createButton('Procesado', 'fa-check', 'btn-success', () =>
                this.setState(
                  item.id,
                  STATE_NAMES.PROCESSED,
                  this.profile.idusuario
                )
              )
            );
          }
        } else {
          if (this.hasPermission(permissions.BOT_REQUEST)) {
            buttons.push(
              this.createButton(
                'Solicitud BOT',
                'fa-check-double',
                'btn-success',
                () =>
                  this.setState(
                    item.id,
                    STATE_NAMES.BOT_REQUEST,
                    this.profile.idusuario
                  )
              )
            );
          }
          if (this.hasPermission(permissions.BOT_REQUEST_PENDING_RECEIPT)) {
            buttons.push(
              this.createButton(
                'Solicitud BOT (Pendiente Boleta)',
                'fa-check',
                'btn-success',
                () =>
                  this.setState(
                    item.id,
                    STATE_NAMES.BOT_PENDING_SLIP,
                    this.profile.idusuario
                  )
              )
            );
          }
        }
        if (
          item.foto_comprobante &&
          this.hasPermission(permissions.DOWNLOAD_RECEIPT) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          item.detalle &&
          item.detalle != 'null' &&
          this.hasPermission(permissions.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.BOT_REQUEST:
      case REQUEST_STATUS.BOT_PENDING_RECEIPT:
      case REQUEST_STATUS.BOT_REVERSED:
        if (item.tipo_transaccion === TRANSACTION_TYPE_VALUES.LOAD_BALANCE) {
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
            ) &&
            item.foto_comprobante !== ''
          ) {
            buttons.push(
              this.createButton(
                'Descargar comprobante',
                'fa-file-image',
                'btn-success',
                () => this.openImageModal(item.id)
              )
            );
          }
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS
            )
          ) {
            buttons.push(
              this.createButton(
                'Detalles de la Solicitud',
                'fa-info',
                'btn-success',
                () => this.openDetailAlert(item)
              )
            );
          }
        } else {
          if (
            item.detalle &&
            item.detalle !== 'null' &&
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS
            )
          ) {
            buttons.push(
              this.createButton(
                'Detalles de la Solicitud',
                'fa-info',
                'btn-success',
                () => this.openDetailAlert(item)
              )
            );
          }
          if (
            item.foto_comprobante &&
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
            ) &&
            item.foto_comprobante !== ''
          ) {
            buttons.push(
              this.createButton(
                'Descargar comprobante',
                'fa-file-image',
                'btn-success',
                () => this.openImageModal(item.id)
              )
            );
          }
        }

        // Este botón se muestra sin restricción de permisos porque no tiene una acción significativa
        buttons.push(
          this.createButton(
            'Se está encargando el BOT...',
            'fas fa-robot fa-spin',
            'btn-secondary',
            () => {}
          )
        );
        break;

      case REQUEST_STATUS.PROCESSED:
        if (
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_REQUEST)
        ) {
          buttons.push(
            this.createButton('Editar', 'fa-pen', 'btn-success', () =>
              this.openEditCw(item.id, item.estado)
            )
          );
        }
        if (
          ![
            TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT,
            TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
          ].includes(item.tipo_transaccion)
        ) {
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.CORRECT_REQUEST
            )
          ) {
            buttons.push(
              this.createButton(
                'Corregir Solicitud',
                'fa-eraser',
                'btn-success',
                () => this.setCorrectionUsed(item),
                item
              )
            );
          }
        }
        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.PREVALIDATED_PROCESSED
          )
        ) {
          buttons.push(
            this.createButton(
              'Procesado Prevalidado',
              'fa-check-double',
              'btn-success',
              () =>
                this.setState(
                  item.id,
                  STATE_NAMES.PREVALIDATED_PROCESSED,
                  this.profile.idusuario
                )
            )
          );
        }
        if (
          [
            TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
            TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
            TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
            TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
          ].includes(item.tipo_transaccion) &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.REVERSE_REQUEST
          )
        ) {
          buttons.push(
            this.createButton(
              'Reversar Solicitud',
              'fa-undo-alt',
              'btn-success',
              () => this.openReverseCw(item.id, item.estado, item.id_pais)
            )
          );
        }
        if (
          [
            TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
            TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
            TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
          ].includes(item.tipo_transaccion) &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.SPLIT_REQUEST
          )
        ) {
          buttons.push(
            this.createButton(
              'Dividir Solicitud',
              'fa-divide',
              'btn-success',
              () => this.openDivideCw(item.id)
            )
          );
        }
        if (
          [
            TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
            TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
          ].includes(item.tipo_transaccion) &&
          item.id_pais === COUNTRIES.ECUADOR &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.PAYOUT)
        ) {
          buttons.push(
            this.createButton('Payout', 'fa-dollar-sign', 'btn-success', () =>
              this.openPayOutCw(item.id)
            )
          );
        }
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.PREVALIDATED_PROCESSED:
        if (
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_REQUEST)
        ) {
          buttons.push(
            this.createButton('Editar', 'fa-pen', 'btn-success', () =>
              this.openEditCw(item.id, item.estado)
            )
          );
        }
        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.REVERSE_REQUEST
          )
        ) {
          buttons.push(
            this.createButton(
              'Reversar Solicitud',
              'fa-undo-alt',
              'btn-success',
              () => this.openReverseCw(item.id, item.estado, item.id_pais)
            )
          );
        }
        if (
          [TRANSACTION_TYPE_VALUES.FINANCIAL].includes(item.tipo_transaccion) &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.SPLIT_REQUEST
          )
        ) {
          buttons.push(
            this.createButton(
              'Dividir Solicitud',
              'fa-divide',
              'btn-success',
              () => this.openDivideCw(item.id)
            )
          );
        }
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.VALIDATED_PROCESSED:
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.NOT_ENTERED:
        if (item.tipo_transaccion === TRANSACTION_TYPE_VALUES.LOAD_BALANCE) {
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.BOT_REQUEST
            )
          ) {
            buttons.push(
              this.createButton(
                'Solicitud BOT',
                'fa-check-double',
                'btn-success',
                () =>
                  this.setState(
                    item.id,
                    STATE_NAMES.BOT_REQUEST,
                    this.profile.idusuario
                  )
              )
            );
          }
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_REQUEST
            )
          ) {
            buttons.push(
              this.createButton('Editar', 'fa-pen', 'btn-success', () =>
                this.openEditCw(item.id, item.estado)
              )
            );
          }
          if (
            item.foto_comprobante &&
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
            ) &&
            item.foto_comprobante !== ''
          ) {
            buttons.push(
              this.createButton(
                'Descargar comprobante',
                'fa-file-image',
                'btn-success',
                () => this.openImageModal(item.id)
              )
            );
          }
          if (
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.CORRECT_REQUEST
            )
          ) {
            buttons.push(
              this.createButton(
                'Corregir Solicitud',
                'fa-eraser',
                'btn-success',
                () => this.setCorrectionUsed(item),
                item
              )
            );
          }
          if (
            item.detalle &&
            item.detalle !== 'null' &&
            this.hasPermission(
              REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS
            )
          ) {
            buttons.push(
              this.createButton(
                'Detalles de la Solicitud',
                'fa-info',
                'btn-success',
                () => this.openDetailAlert(item)
              )
            );
          }
        }
        break;

      case REQUEST_STATUS.DIVIDED:
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }

        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.REJECTED_COMMENT:
      case REQUEST_STATUS.REJECTED:
      case REQUEST_STATUS.REVERSED:
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }

        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;

      case REQUEST_STATUS.CORRECTION:
        if (
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_REQUEST)
        ) {
          buttons.push(
            this.createButton('Editar', 'fa-pen', 'btn-success', () =>
              this.openEditCw(item.id, item.estado)
            )
          );
        }
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_CORRECTION
          )
        ) {
          buttons.push(
            this.createButton(
              'Editar Corrección',
              'fa-pen',
              'btn-success',
              () => this.openEditCw(item.id, item.estado)
            )
          );
        }
        break;

      case REQUEST_STATUS.BOT_PROBLEM:
        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.REJECT_REQUEST
          )
        ) {
          buttons.push(
            this.createButton('Rechazar', 'fa-ban', 'btn-danger', () =>
              this.setState(
                item.id,
                STATE_NAMES.REJECTED,
                this.profile.idusuario
              )
            )
          );
        }

        if (
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.BOT_REQUEST)
        ) {
          buttons.push(
            this.createButton(
              'Solicitud BOT',
              'fa-check-double',
              'btn-success',
              () =>
                this.setState(
                  item.id,
                  STATE_NAMES.BOT_REQUEST,
                  this.profile.idusuario
                )
            )
          );
        }

        if (
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.BOT_REQUEST_PENDING_RECEIPT
          )
        ) {
          buttons.push(
            this.createButton(
              'Solicitud BOT (Pendiente Boleta)',
              'fa-check',
              'btn-success',
              () =>
                this.setState(
                  item.id,
                  STATE_NAMES.BOT_PENDING_SLIP,
                  this.profile.idusuario
                )
            )
          );
        }

        if (
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.EDIT_REQUEST)
        ) {
          buttons.push(
            this.createButton('Editar', 'fa-pen', 'btn-success', () =>
              this.openEditCw(item.id, item.estado)
            )
          );
        }
        if (
          item.foto_comprobante &&
          this.hasPermission(
            REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.DOWNLOAD_RECEIPT
          ) &&
          item.foto_comprobante !== ''
        ) {
          buttons.push(
            this.createButton(
              'Descargar comprobante',
              'fa-file-image',
              'btn-success',
              () => this.openImageModal(item.id)
            )
          );
        }
        if (
          item.detalle &&
          item.detalle !== 'null' &&
          this.hasPermission(REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS.VIEW_DETAILS)
        ) {
          buttons.push(
            this.createButton(
              'Detalles de la Solicitud',
              'fa-info',
              'btn-success',
              () => this.openDetailAlert(item)
            )
          );
        }
        break;
    }

    // if (this.isMobile) {
    //   return buttons.filter((button) => {
    //     if (button.tooltip === 'Editar') {
    //       return this.correctionUsed[item.id] || item.estado === STATE_NAMES.CORRECTION;
    //     }
    //     return (
    //       button.tooltip === 'Corregir Solicitud' || button.tooltip === 'Editar'
    //     );
    //   });
    // }

    return buttons;
  }

  setCorrectionUsed(item: any) {
    this.setStateWithCorrection(
      item.id,
      STATE_NAMES.CORRECTION,
      this.profile.idusuario,
      () => {
        this.correctionUsed[item.id] = true;
      }
    );
  }

  setStateWithCorrection(
    id: number,
    newState: number,
    idusuarioSet: number,
    callback: () => void
  ) {
    this.alertService
      .showConfirmAlert(
        '¿Estás seguro?',
        'No podrás revertir esto!',
        'Sí, cambiar estado!',
        'Cancelar'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.handleStateChange(id, newState, idusuarioSet, callback);
        }
      });
  }

  handleStateChange(
    id: number,
    newState: number,
    idusuarioSet: number,
    callback: () => void
  ) {
    const stateActions: {
      [key: number]: (
        id: number,
        newState: number,
        idusuarioSet: number,
        callback: () => void
      ) => void;
    } = {
      [STATE_NAMES.REJECTED]: this.promptForReason.bind(this),
      [STATE_NAMES.CORRECTION]: this.promptForReason.bind(this),
    };

    const action = stateActions[newState] || this.defaultStateChange.bind(this);

    action(id, newState, idusuarioSet, callback);
  }

  promptForReason(
    id: number,
    newState: number,
    idusuarioSet: number,
    callback: () => void
  ) {
    const title =
      newState === STATE_NAMES.REJECTED
        ? 'Escribe el motivo del rechazo'
        : 'Escribe el motivo de la corrección';

    this.alertService
      .showInputAlert(
        title,
        'Motivo',
        'Escribe aquí...',
        'Enviar motivo',
        'Cancelar'
      )
      .then((textResult) => {
        if (textResult.isConfirmed && textResult.value) {
          this.updateStateWithReason(
            id,
            newState,
            idusuarioSet,
            textResult.value
          );
          callback();
        }
      });
  }

  defaultStateChange(
    id: number,
    newState: number,
    idusuarioSet: number,
    callback: () => void
  ) {
    this.updateState(id, newState, idusuarioSet);
    callback();
  }

  hasCorrectionOrEditButton(item: any): boolean {
    const buttons = this.getButtons(item);
    return buttons.some(
      (button) =>
        button.tooltip === 'Corregir Solicitud' || button.tooltip === 'Editar'
    );
  }

  //* CARD
  showMenu: boolean = false;
  showSubMenu: boolean = false;
  toggleMenu(index: number, item: any) {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.actualIndex = index;
      this.actualItemClient = this.convertObjectToArray({
        Identificación: item.identificacion,
        'Nombre Titular': item.nombre_titular,
        Beneficiario: item.formatted_name,
        'Número de Cuenta': item.numero_cuenta,
        Banco: item.bank,
      });
      this.actualItemEnterprise = this.convertObjectToArray({
        Banco: item.bank_enterprise,
        'Titular Bancario': item.formatted_holder_name,
      });

      this.buttons = this.getButtons(item);

      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  convertObjectToArray(obj: any): { key: string; value: any }[] {
    return Object.keys(obj)
      .filter((key) => obj[key] !== 'null')
      .map((key) => ({ key: key, value: obj[key] }));
  }

  expandCard(index: number) {
    this.cardExpanded[index] = !this.cardExpanded[index];
  }

  closeMenu() {
    this.showMenu = false;
    this.showSubMenu = false;
    document.body.style.overflowY = 'auto';
  }

  openSubMenu(itemType: string) {
    this.itemType = itemType;
    if (itemType === 'client') {
      this.actualItem = this.actualItemClient;
    } else if (itemType === 'enterprise') {
      this.actualItem = this.actualItemEnterprise;
    }
    this.showSubMenu = true;
  }

  closeSubMenu() {
    this.showMenu = true;
    this.showSubMenu = false;
  }

  // OTHERS

  exportToExcel() {
    const dataToExport = this.cwData.map((item: any) => ({
      satus: this.getStateClasses(item.estado).text.replace(/<br>/g, ' '),
      amount: item.monto,
      transactionType: this.transactionTypes[item.tipo_transaccion].label,
      created: moment(item.creado).format('DD/MM/YYYY HH:mm:ss'),
      client:
        item.agent || item.jugador || item.accounting_account.nombre_cuenta,
      receipt: item.comprobante,
      enterpriseReceipt: item.comprobante_empresa,
      repeat_receipt: item.permitir_comprobante_repetido === 1 ? 'Si' : 'No',
      holder: item.holder,
      beneficiary:
        item.nombre_titular && item.nombre_titular !== 'null'
          ? item.nombre_titular
          : '',
      clientBank: item.bank,
      identifiaction:
        item.identificacion && item.identificacion !== 'null'
          ? item.identificacion
          : '',
      accountNumber:
        item.numero_cuenta && item.numero_cuenta !== 'null'
          ? item.numero_cuenta
          : '',
      enterpriseBank: item.bank_enterprise,
      updated: moment(item.actualizado).format('DD/MM/YYYY HH:mm:ss'),
    }));

    this.excelExportService.exportToExcel(
      dataToExport,
      'solicitud_CR',
      headersExcel
    );
  }
}
