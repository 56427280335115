import moment from 'moment';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

export const AVAILABLE_LANGUAGES = {
  ES: 'es',
  EN: 'us',
  PT: 'pt',
};

export const AVAILABLE_THEME = {
  DARK: 'dark-only',
  WHITE: '',
};

export const AGENT_ID_DEFAULT = 0;

export const TIME_OUT = {
  /*
  5 is the number of minutes we want to convert
  60 is the number of seconds in a minute.
  1000 is the number of ms in a second
*/
  TIME: 15 * 60 * 1000,
  // TIME: 5 * 1000
};

export const userOnlyActive = 1;

export const userActiveAndInactive = undefined;

export const agentAfiliateAccountant = [3, 4, 5];

export const DATE_PICKER_RANGE_LABELS = {
  TODAY: 'datepicker.range.Today',
  YESTERDAY: 'datepicker.range.Yesterday',
  LAST_7_DAYS: 'datepicker.range.Last7Days',
  LAST_30_DAYS: 'datepicker.range.Last30Days',
  THIS_MONTH: 'datepicker.range.ThisMonth',
  LAST_MONTH: 'datepicker.range.LastMonth',
};
export const CODE_BONUS_CONSTANS = {
  ATC: 1,
  PAGOS: 2,
  ADMINISTRADOR: 3,
  AGENTE: 4,
  AFILIADO: 5,
  PAGE: 1,
  LIMIT_TABLE: 10,
};
export const defaultMinDatepickerV1 = '2020-01-02';

export const defaultInitialDateRangepickerV1 = [
  moment().subtract(1, 'years').startOf('day').toDate(),
  moment().add(1, 'days').startOf('day').toDate(),
];
export const defaultInitialDateRangepickerV2 = [
  moment().subtract(1, 'month').startOf('month').toDate(),
  moment().subtract(1, 'month').endOf('month').toDate(),
];

export const defaultInitialDateRangepickerV3 = [
  moment().startOf('month').toDate(),
  moment().endOf('month').toDate(),
];

export const defaultRangesDatepickerV1 = [
  {
    value: [
      moment('2020-01-01 00:00:00').toDate(),
      moment().add(1, 'days').startOf('day').toDate(),
    ],
    label: 'Todo',
  },
  {
    value: [
      moment().startOf('day').toDate(),
      moment().add(1, 'days').startOf('day').toDate(),
    ],
    label: 'Hoy',
  },
  {
    value: [
      moment().subtract(1, 'days').startOf('day').toDate(),
      moment().startOf('day').toDate(),
    ],
    label: 'Ayer',
  },
  {
    value: [
      moment().subtract(6, 'days').startOf('day').toDate(),
      moment().add(1, 'days').startOf('day').toDate(),
    ],
    label: 'Ultimos 7 Días',
  },
  {
    value: [
      moment().subtract(29, 'days').startOf('day').toDate(),
      moment().add(1, 'days').startOf('day').toDate(),
    ],
    label: 'Ultimos 30 Días',
  },
  {
    value: [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ],
    label: 'Este Mes',
  },
  {
    value: [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate(),
    ],
    label: 'Mes Pasado',
  },
];

export const CURRENCY_BY_COUNTRY_CODE: Record<
  string,
  { currency: string; locale: string; symbol: string }
> = {
  EC: { currency: 'USD', locale: 'es-EC', symbol: '$' },
  PE: { currency: 'PEN', locale: 'es-PE', symbol: 'S/' },
};

export const DAFAULT_PAGINATION_OPTIONS = [10, 20, 50, 100, 5000, 10000];

export const CONFIG_BASE_DATE_RANGEPICKER: {
  minDate: Date;
  maxDate: Date;
  initialRange: Date[];
  bsConfig: Partial<BsDaterangepickerConfig>;
} = {
  minDate: moment('2020-01-01 00:00:00').toDate(),
  maxDate: moment().add(1, 'days').startOf('day').toDate(),
  initialRange: [
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ],
  bsConfig: {
    minMode: 'day',
    keepDatesOutOfRules: true,
    containerClass: 'theme-blue',
    showPreviousMonth: true,
    ranges: defaultRangesDatepickerV1,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
    preventChangeToNextMonth: true,
  },
};

export const ACTION_SOCKET = {
  ADD: 'A',
  UPDATE: 'U',
  DELETE: 'D',
};

export const GENERAL_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const NG_SELECT_TYPE = {
  COUNTRY: 'country',
  AGENT: 'agent',
  BANK: 'bank',
  CURRENCY: 'currency',
  HOLDER: 'holder',
  GENDER: 'gender',
  LANGUAGE: 'language',
  CITY: 'city',
  BONUS: 'bonus',
};
