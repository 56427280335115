<div class="row">
  <div class="col-sm-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5
          class="card-title d-flex justify-content-between align-items-center"
        >
          Filtro
          <button
            class="btn btn-link"
            data-bs-toggle="collapse"
            data-bs-target="#filterCardContent"
            aria-expanded="false"
            aria-controls="filterCardContent"
            (click)="toggleChart()"
          >
            <i
              class="fas"
              [ngClass]="{
                'fa-arrow-up': showFilter,
                'fa-arrow-down': !showFilter
              }"
            ></i>
          </button>
        </h5>
      </div>
      <div id="filterCardContent" class="collapse">
        <div class="card-body" [ngClass]="{ 'mobile-overflow': isMobileRedux }">
          <div class="col-xl-12 col-md-12">
            <div
              [ngClass]="{ open: showFilter }"
              id="filter"
              [ngStyle]="getShowFilterStyle()"
              class="row row-mobile"
            >
              <div class="col-sm-12 col-xl-12">
                <div class="row">
                  <div class="col-sm-12">
                    <form
                      [formGroup]="listSpFormFilter"
                      style="padding-right: 2%"
                    >
                      <div class="row mb-2">
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Fecha</label>
                          <input
                            bsDaterangepicker
                            [bsConfig]="bsConfig"
                            [minDate]="bsConfig.minDate"
                            [maxDate]="bsConfig.maxDate"
                            type="text"
                            class="form-control"
                            formControlName="dateRange"
                            placeholder="Ingrese la fecha"
                            [bsValue]="initialRange"
                          />
                        </div>
                        @if (this.profile?.id_rol !== ROLES.AGENT) {
                        <div class="col-sm-3">
                          <div class="media">
                            <label class="col-form-label m-r-10">
                              {{ isAgent ? "Agente" : "Cuenta Contable" }}
                            </label>
                            <div
                              class="media-body text-end switch-sm icon-state"
                            >
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  (change)="toggleSwitch()"
                                />
                                <span class="switch-state"></span>
                              </label>
                            </div>
                          </div>
                          @if (isAgent) {
                          <ng-select
                            [items]="allAgentsData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="id_agente"
                            class="mb-2"
                            placeholder="Selecciona un agente"
                            (scrollToEnd)="onScrollToEnd()"
                            (search)="onSearch($event.term)"
                            (clear)="onSearch('')"
                            (open)="onSearch('')"
                          ></ng-select>
                          }@else{
                          <ng-select
                            bindLabel="label"
                            bindValue="value"
                            [items]="optionsAccountingAccount$ | async"
                            required=""
                            formControlName="id_codigo"
                            placeholder="Selecciona una cuenta contable"
                          >
                          </ng-select>
                          }
                        </div>
                        }
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Comprobante</label>
                          <input
                            type="text"
                            formControlName="receipt"
                            class="form-control custom-input"
                            placeholder="Ingresa un comentario"
                          />
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Monto</label>
                          <input
                            type="text"
                            formControlName="amount"
                            class="form-control custom-input"
                            placeholder="Ingresa un monto"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">País</label>
                          <ng-select
                            [items]="optionsCountries$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="country"
                            class="mb-2"
                            placeholder="Selecciona un país"
                          ></ng-select>
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Estado</label>
                          <ng-select
                            [items]="optionsState$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="state"
                            class="mb-2"
                            placeholder="Selecciona un estado"
                          ></ng-select>
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label"
                            >Tipo de Movimiento</label
                          >
                          <ng-select
                            [items]="optionsMovementType$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="movement_type"
                            class="mb-2"
                            placeholder="Selecciona un estado"
                          ></ng-select>
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Banco</label>
                          <ng-select
                            [items]="allBankData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="bank"
                            class="mb-2"
                            placeholder="Selecciona un banco"
                            (scrollToEnd)="onScrollToEnd()"
                            (search)="onSearch($event.term)"
                            (open)="onSearch('')"
                            (clear)="onSearchBank()"
                            (change)="onBankSelected($event)"
                          ></ng-select>
                        </div>
                        @if (this.profile?.id_rol !== ROLES.AGENT) {
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Titular</label>
                          <ng-select
                            [items]="allHoldersData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="holder"
                            class="mb-2"
                            placeholder="Selecciona una opción"
                            (scrollToEnd)="onScrollToEnd()"
                            (search)="onSearch($event.term)"
                            (clear)="onSearch('')"
                            (open)="onSearch('')"
                          ></ng-select>
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Creado por</label>
                          <ng-select
                            [items]="createUser$ | async"
                            bindLabel="label"
                            bindValue="value"
                            class="mb-2"
                            formControlName="created_by"
                            placeholder="Selecciona una opción"
                          ></ng-select>
                        </div>
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Fecha Bancaria</label>
                          <input
                            bsDaterangepicker
                            [bsConfig]="bsConfig"
                            [minDate]="bsConfig.minDate"
                            [maxDate]="bsConfig.maxDate"
                            type="text"
                            class="form-control"
                            formControlName="bank_date_range"
                            placeholder="Seleccione la fecha bancaria"
                          />
                        </div>
                        }
                        <div
                          class="col-sm-3"
                          [class.col-sm-3]="
                            this.profile?.id_rol !== ROLES.AGENT
                          "
                          [class.col-sm-4]="
                            this.profile?.id_rol === ROLES.AGENT
                          "
                        >
                          <label class="col-form-label">Observacion</label>
                          <input
                            type="text"
                            formControlName="observation"
                            class="form-control custom-input"
                            placeholder="Ingresa un comentario"
                          />
                        </div>
                      </div>
                    </form>
                    <div
                      class="text-end"
                      style="padding-right: 2%; padding-bottom: 13px"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-restore me-1"
                        (click)="resetForm()"
                      >
                        Restablecer
                      </button>
                      <button
                        class="btn btn-sm btn-head me-1"
                        (click)="sendFilter()"
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
