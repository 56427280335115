<div class="card">
  <div class="card-header">
    <h4 class="card-title mb-0">Configuración Bancaria</h4>
  </div>
  <div class="card-body">
    <form
      [formGroup]="bankSettingsForm"
      [class.was-validated]="!bankSettingsForm.valid"
      novalidate=""
      (ngSubmit)="onSubmitBankSettings()"
    >
      <div class="row">
        <div class="col-sm-6">
          <label>Banco</label>
          <ng-select
            [items]="alltBankData"
            bindLabel="label"
            bindValue="value"
            formControlName="bankId"
            placeholder="Selecciona un banco"
            (scrollToEnd)="onScrollToEnd()"
            (search)="onSearch($event.term)"
            (open)="onSearch('')"
            (clear)="onSearchBank()"
          >
          </ng-select>
          @if (bankSettingsForm.controls.bankId!.touched &&
          bankSettingsForm.controls.bankId.errors?.required) {
          <div class="text-danger font-error mt-2">El Banco es requerido</div>
          }
        </div>
        <div class="col-sm-6">
          <label>Número de Cuenta</label>
          <input
            type="text"
            class="form-control custom-input"
            id="accountNumber"
            required=""
            formControlName="accountNumber"
            (keyup)="validateField($event, 'accountNumber')"
          />
          @if (numberAccountError) {
          <div class="text-danger font-error mt-2">
            Solo se permite números, letras y guion como caracter especial.
          </div>
          } @if (bankSettingsForm.controls.accountNumber.touched &&
          bankSettingsForm.controls.accountNumber.errors?.required) {
          <div class="text-danger font-error mt-2">
            El número de cuenta es requerido
          </div>
          }
        </div>

        <div class="text-end mt-2">
          @if (bankSettingsForm.enabled) {
          <button
            class="primary-button-cancel"
            style="margin-right: 10px"
            (click)="resetForm(bankSettingsForm)"
          >
            Cancelar
          </button>
          <button
            type="submit"
            class="primary-button"
            [disabled]="!isFormModified || bankSettingsForm.invalid"
          >
            Actualizar
          </button>
          } @else {
          <button
            class="primary-button"
            title="Habilitar formulario"
            (click)="enableFormProfile()"
          >
            Cambiar
          </button>
          }
        </div>
      </div>
    </form>
  </div>
</div>
