import { Data } from '@angular/router';

export interface AuthState {
  username: string;
  password: string;
}
export interface UserResponse {
  code: number;
  data: User[];
  message: string;
  status: string;
}

export interface VerifyResponse {
  data: VerifyDataResponse;
}
export interface VerifyDataResponse {
  idusuario: string;
  usuario: string;
  TFA: number;
  token: string;
}
export interface User {
  idusuario: number;
  id_usuproveedor: number;
  id_usuproveedor2: number;
  id_usuproveedor3: number;
  id_externo: string;
  tiene_ruc: number;
  identificacion: string;
  actividad_principal: string;
  nombres: string;
  celular: string;
  usuario: string;
  contrasena: string;
  tipo_usuario: number;
  tipo_usuario_admin: number;
  activar: number;
  fecha_creacion: Date;
  porcentaje_propio: number;
  porcentaje_slot: number;
  arrastre: number;
  porcentaje_fijo: number;
  actividad_agente: number;
  porcentaje_dar: number;
  porcentaje_dar2: number;
  porcentaje_dar3: number;
  requerimientos: string;
  fecha_nacimiento: Date;
  id_provincia: number;
  ciudad: string;
  direccion: string;
  correo: string;
  tipo_cierre: number;
  autorizar_prestamo: number;
  entorno: string;
  buscar_palabra1: string;
  buscar_palabra2: string;
  buscar_palabra3: string;
  observacion: string;
  permisos_root: number;
  cargar_desde: string;
  id_excel: number;
  comprobar: string;
  created_at: Date;
  updated_at: Date;
  id_pais: number;
  grupo_ws: string;
  bono_mes: number;
  porcentaje_apostado: number;
  actualizado: Date;
  id_usuario_add: number;
  id_usuario_set: number;
  id_grupo_banco: number;
  token_app: string;
  id_ciudad: number;
  foto_cedula_frente: string;
  foto_cedula_detras: string;
  foto_selfie: string;
  detalle_editado_movil: string;
  clave_agent: string;
  TFA: number;
  twofactorysecret: string;
  bono_ilimitado: number;
  id_rol: number;
  sueldo_quincena?: number;
  sueldo_fin_mes?: number;
  tipo_sueldo?: number;
  id_banco_cuenta?: number;
  numero_cuenta?: string;
}
export interface CDImage {
  data: Data;
  contentType: string;
}
export interface objectCurrency {
  code: string;
  name: string;
}
export interface IProfileState extends User {
  loading: boolean;
  updating?: boolean;
  error?: any;
  data?: any;
  message?: string;
}
