//Modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

//Directives
import { AdiButtonLoaderIconDirective } from './components/buttons/custom-button/custom-button.directive';
import { ValidateDecimalNumberDirective } from './directives/validate-number-input.directive';
import { FormControlErrorDirective } from './directives/form-control-error.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ContentBodyBasicAccordionDirective } from './directives/content-body-basic-accordion.directive';

// Components
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { FullComponent } from './components/layout/full/full.component';
import { ContentComponent } from './components/layout/content/content.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { PageWithVideoComponent } from './coming-soon/page-with-video/page-with-video.component';
import { PageWithImageComponent } from './coming-soon/page-with-image/page-with-image.component';
import { SimpleComponent } from './coming-soon/simple/simple.component';
import { Error400Component } from './error/error400/error400.component';
import { Error401Component } from './error/error401/error401.component';
import { Error403Component } from './error/error403/error403.component';
import { Error404Component } from './error/error404/error404.component';
import { Error500Component } from './error/error500/error500.component';
import { Error503Component } from './error/error503/error503.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { FilterComponent } from './components/filter/filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { FormsModalComponent } from './components/modals/forms-modal/forms-modal.component';
import { ChangeAuditModalComponent } from './components/modals/customize-modals/change-audit-modal/change-audit-modal.component';
import { TfaModalComponent } from './components/modals/customize-modals/tfa-modal/tfa-modal.component';
import { NgxEditorModule } from 'ngx-editor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeIconComponent } from './components/font-awesome-icon/font-awesome-icon.component';
import { ParseJsonPipe } from '../core/pipes/parse-json.pipe';
import { DatepickerV1Component } from './components/datepickers/datepicker-v1/datepicker-v1.component';
import { TabV1Component } from './components/tabs/tab-v1/tab-v1.component';
import { LightDescriptionComponent } from './components/cards/light-description/light-description.component';
import { CustomButtonComponent } from './components/buttons/custom-button/custom-button.component';
import { BasicTableComponent } from './components/tables/basic-table/basic-table.component';
import { CustomPaginationComponent } from './components/paginations/custom-pagination/custom-pagination.component';
import { BasicAccordionComponent } from './components/accordion/basic-accordion/basic-accordion.component';
import { BootstrapModalComponent } from './components/modals/bootstrap-modal/bootstrap-modal.component';
import { MobileComponent } from './components/layout/mobile/mobile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChangeListModalsComponent } from './components/modals/customize-modals/change-list-modals/change-list-modals/change-list-modals.component';
import { EllipsisOverflowDirective } from './directives/ellipsis-overflow.directive';
import { NoNegativeNumberAndOnlyNumberDirective } from './directives/no-negative-number-and-only-number.directive';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';

@NgModule({
  declarations: [
    AdiButtonLoaderIconDirective,
    ParseJsonPipe,
    FeatherIconsComponent,
    TapToTopComponent,
    FullComponent,
    ContentComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    SvgIconComponent,
    LoaderComponent,
    LanguagesComponent,
    MyAccountComponent,
    NotificationComponent,
    ComingSoonComponent,
    NotFoundPageComponent,
    CustomizerComponent,
    PageWithVideoComponent,
    PageWithImageComponent,
    SimpleComponent,
    Error400Component,
    Error401Component,
    Error403Component,
    Error404Component,
    Error500Component,
    Error503Component,
    MaintenanceComponent,
    FilterComponent,
    DynamicFormComponent,
    FormsModalComponent,
    ChangeAuditModalComponent,
    TfaModalComponent,
    FontAwesomeIconComponent,
    DatepickerV1Component,
    TabV1Component,
    LightDescriptionComponent,
    CustomButtonComponent,
    BasicTableComponent,
    CustomPaginationComponent,
    BasicAccordionComponent,
    ContentBodyBasicAccordionDirective,
    OnlyNumbersDirective,
    ValidateDecimalNumberDirective,
    FormControlErrorDirective,
    BootstrapModalComponent,
    MobileComponent,
    ChangeListModalsComponent,
    EllipsisOverflowDirective,
    NoNegativeNumberAndOnlyNumberDirective,
    CopyToClipboardDirective,
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxEditorModule,
    NgSelectModule,
    AngularEditorModule,
    // DragulaModule.forRoot(),
    TranslateModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    CustomButtonComponent,
    AdiButtonLoaderIconDirective,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TapToTopComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    ComingSoonComponent,
    PageWithVideoComponent,
    PageWithImageComponent,
    SimpleComponent,
    Error400Component,
    Error401Component,
    Error403Component,
    Error404Component,
    Error500Component,
    Error503Component,
    MaintenanceComponent,
    FilterComponent,
    DynamicFormComponent,
    TfaModalComponent,
    NgxEditorModule,
    NgSelectModule,
    AngularEditorModule,
    DatepickerV1Component,
    TabV1Component,
    LightDescriptionComponent,
    BasicTableComponent,
    CustomPaginationComponent,
    BasicAccordionComponent,
    ContentBodyBasicAccordionDirective,
    OnlyNumbersDirective,
    ValidateDecimalNumberDirective,
    FormControlErrorDirective,
    //directivas
    EllipsisOverflowDirective,
    NoNegativeNumberAndOnlyNumberDirective,
    CopyToClipboardDirective,
  ],
})
export class SharedModule {}
