import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  ChargesWithdrawalsRequestEnum,
} from '../constants/endpoints.enum';
import {
  ApiDataResponse,
  ApiResponse,
} from '../interfaces/api-response.inteface';
import { ProfitPerPlayer } from '../interfaces/dashboard.interface';
import { DateRange, PaginationParams } from '../interfaces/filters.interface';

@Injectable({
  providedIn: 'root',
})
export class ChargesWithdrawalsRequestService {
  private API_URL = `${environment.API_URL}${ApiPathEnum.ChargesWithdrawalsRequest}`;

  constructor(private http: HttpClient) {}

  getAllData(page: number, limit: number, filters?: any): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    });

    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.GetChargesWithdrawals}`,
      { params }
    );
  }

  getImgCw(cwId: number): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.GET_IMG_ENDPOINT}/${cwId}`
    );
  }

  getCw(cwId: number): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.GET_CW}/${cwId}`
    );
  }

  createRequest(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.CreateRequest}`,
      formData
    );
  }

  setRequest(data: any): Observable<any> {
    return this.http.patch(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.SetRequest}`,
      data
    );
  }

  setState(
    cwId: number,
    newState: number,
    idusuarioSet: number,
    details?: string,
    transactionType?: number
  ): Observable<any> {
    const body = {
      id: cwId,
      estado: newState,
      id_usuario_set: idusuarioSet,
      detalle: details,
      tipo_transaccion: transactionType,
    };
    return this.http.patch(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.SetState}`,
      body
    );
  }

  getPlayerTransactionsPerAgent(
    filters: PaginationParams & DateRange & { [key: string]: any }
  ): Observable<ApiResponse<{ data: ProfitPerPlayer[], totalCount: number }>> {
    const endpoint = `${ChargesWithdrawalsRequestEnum.GetPlayerTransactionsPerAgent}`;
    const url = `${this.API_URL}${endpoint}`;

    return this.http.post<ApiResponse<{ data: ProfitPerPlayer[], totalCount: number }>>(url, {
      ...filters,
    });
  }

  createReverse(data: any): Observable<any> {
    return this.http.patch(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.CreateReverse}`,
      data
    );
  }

  createDivision(data: any): Observable<any> {
    return this.http.patch(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.CreateDivision}`,
      data
    );
  }

  getUserCreatedRegister(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsRequestEnum.getUserCreatedRequest}`
    );
  }
}
