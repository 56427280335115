import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forms-modal',
  templateUrl: './forms-modal.component.html',
  styleUrl: './forms-modal.component.scss',
})
export class FormsModalComponent {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainer!: ViewContainerRef;
  @Input() component: any;
  componentRef!: ComponentRef<any>;
  @Input()
  title!: string;

  constructor(
    public activeModal: NgbActiveModal,
    config: NgbModalConfig,
    private resolver: ComponentFactoryResolver,

    private cdr: ChangeDetectorRef // Añadido ChangeDetectorRef
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  showModal: boolean = false;

  ngAfterViewInit() {
    if (this.component) {
      const componentFactory = this.resolver.resolveComponentFactory(
        this.component
      );
      this.dynamicComponentContainer.clear();
      this.componentRef =
        this.dynamicComponentContainer.createComponent(componentFactory);
      this.cdr.detectChanges();
    }
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
