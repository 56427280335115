import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AccountingAccountEndpointEnum,
  ApiPathEnum,
  BankEndpointEnum,
  CodeBonusEndpointEnum,
  CountryEndPointEnum,
  FinancialEndPointEnum,
} from '../constants/endpoints.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FinancialRegisterStatus } from '../interfaces/financial-register-status';

@Injectable({
  providedIn: 'root',
})
export class CodeBonusService {
  public API_URL_CODE_BONUS = `${environment.API_URL}${ApiPathEnum.CodeBonus}`;
 /*  public API_URL_ACCOUNTING_ACCOUNT = `${environment.API_URL}${ApiPathEnum.AccountingAccount}`;
  public API_URL_COUNTRY = `${environment.API_URL}${ApiPathEnum.Country}`;
  public API_URL_FINANCIAL = `${environment.API_URL}${ApiPathEnum.Financial}`; */

  constructor(private http: HttpClient) {}

  getAllCodeBonus(page: number, limit: number, filters?: any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAllBonusCode}`;
    const params = { page: page.toString(), limit: limit.toString()};
    return this.http.post<any>(url, { params,filters });
  }

  updateStateBonusCode(request: any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.UpdateStateBonusCode}`;
    return this.http.put<any>(url, request);
  }
  createCodeBonus(request: any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.CreateBonusCodes}`;
    return this.http.post<any>(url, request);
  }
  getAllTypeBonus(): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAllTypeBonus}`;
    return this.http.get<any>(url);
  }
  getCodeBonusByAgent(agentId: number, page: number, limit: number,filters?: any): Observable<any> {
    const params = {
      page,
      limit,
      agentId,
    };
    return this.http.post<any>(`${this.API_URL_CODE_BONUS}/get-by-agent`, { params,filters });
  }
  getAgentBalance(request:any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAgentBalance}`;
    return this.http.post<any>(url, request);
  }
}
