<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <div class="row">
        <div class="col-sm-12">
          <form [formGroup]="cwFormFilter">
            <div class="row mb-3">
              <div class="col-sm-4">
                <label class="col-form-label">Fecha de creación</label>
                <input
                  bsDaterangepicker
                  [bsConfig]="bsConfig"
                  [minDate]="bsConfig.minDate"
                  [maxDate]="bsConfig.maxDate"
                  type="text"
                  class="form-control"
                  formControlName="dateRange"
                  placeholder="Ingrese la fecha"
                  [bsValue]="initialRange"
                />
              </div>
              <div class="col-sm-4">
                <label class="col-form-label">Monto</label>
                <input
                  type="text"
                  formControlName="amount"
                  class="form-control custom-input"
                  placeholder="Ingresa un monto"
                />
              </div>
              <div class="col-sm-4">
                @if (this.profile?.id_rol !== roles.AGENT) {
                <div class="media">
                  <label class="col-form-label m-r-10">
                    {{ isAgent ? "Agente" : "Jugador" }}
                  </label>
                  <div class="media-body text-end switch-sm icon-state">
                    <label class="switch">
                      <input type="checkbox" (change)="toggleSwitch()" />
                      <span class="switch-state"></span>
                    </label>
                  </div>
                </div>
                @if (isAgent) {
                <ng-select
                  [items]="allAgentsData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="id_agente"
                  placeholder="Selecciona un agente"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                  (change)="onSelectionChange()"
                ></ng-select>
                }@else {
                <ng-select
                  [items]="allPlayersData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="id_jugador"
                  placeholder="Selecciona un jugador"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                  (change)="onSelectionChange()"
                ></ng-select>
                } }@else {
                <label class="col-form-label">Tipo de usuario</label>

                <ng-select
                  [items]="optionTypeUser$ | async"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="type_user"
                  class="mb-2"
                  placeholder="Selecciona una opción"
                >
                </ng-select>
                }
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-4">
                <label class="col-form-label">Comprobante</label>
                <input
                  type="text"
                  formControlName="receipt"
                  class="form-control custom-input"
                  placeholder="Ingresa un comprobante"
                />
              </div>
              <div class="col-sm-4">
                <label class="col-form-label">Banco</label>
                <ng-select
                  [items]="allBankData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="bank"
                  class="mb-2"
                  placeholder="Selecciona un banco"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (open)="onSearch('')"
                  (clear)="onSearchBank()"
                  (change)="onBankSelected($event)"
                ></ng-select>
              </div>
              <div class="col-sm-4">
                <label class="col-form-label">Tipo de transacción</label>
                <ng-select
                  [items]="optionsTransactionTypes$ | async"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="transaction_type"
                  class="mb-2"
                  placeholder="Selecciona un tipo de transacción"
                ></ng-select>
              </div>
            </div>
            @if (this.profile?.id_rol !== roles.AGENT) {
            <div class="d-flex align-items-center mb-3">
              <hr class="flex-grow-1 mx-3 my-0" />
              <p class="card-title mb-0 fw-bold">Filtros Avanzados</p>
              <button class="btn btn-link p-0 ms-3" (click)="advancedFilters()">
                <i
                  [class]="
                    'fas fa-arrow-' + (showadvancedFilters ? 'up' : 'down')
                  "
                  style="font-size: 14px; color: #5a6268"
                ></i>
              </button>
            </div>
            <div [ngClass]="{ open: showadvancedFilters }" id="advancedFilters">
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label class="col-form-label">Titular</label>
                  <ng-select
                    [items]="allHoldersData"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="holder"
                    class="mb-2"
                    placeholder="Selecciona una opción"
                    (scrollToEnd)="onScrollToEnd()"
                    (search)="onSearch($event.term)"
                    (clear)="onSearch('')"
                    (open)="onSearch('')"
                  ></ng-select>
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">País</label>
                  <ng-select
                    [items]="optionsCountries$ | async"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="country"
                    class="mb-2"
                    placeholder="Selecciona un país"
                  ></ng-select>
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Fecha de actualización</label>
                  <!-- <shared-datepicker-v1></shared-datepicker-v1> -->
                  <input
                    bsDaterangepicker
                    [bsConfig]="bsConfig"
                    [minDate]="bsConfig.minDate"
                    [maxDate]="bsConfig.maxDate"
                    type="text"
                    class="form-control"
                    formControlName="dateUpdate"
                    placeholder="Ingrese la fecha"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label class="col-form-label"
                    >Transferencia Interbancaria</label
                  >
                  <ng-select
                    [items]="optionsInterbankTransfer$ | async"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="interbank_transfer"
                    class="mb-2"
                    placeholder="Selecciona una opción"
                  ></ng-select>
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Identificación</label>
                  <input
                    type="text"
                    class="form-control custom-input"
                    formControlName="identification"
                    placeholder="Ingresa una identificación"
                  />
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Beneficiario</label>
                  <input
                    type="text"
                    class="form-control custom-input"
                    formControlName="beneficiary"
                    placeholder="Ingresa un beneficiario"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <label class="col-form-label">N° Cuenta</label>
                  <input
                    type="text"
                    class="form-control custom-input"
                    formControlName="account_number"
                    placeholder="Ingresa un número de cuenta"
                  />
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Creado por</label>
                  <ng-select
                    [items]="createUser$ | async"
                    bindLabel="label"
                    bindValue="value"
                    class="mb-2"
                    formControlName="created_by"
                    placeholder="Selecciona una opción"
                  ></ng-select>
                </div>
                <div class="col-sm-4">
                  <label class="col-form-label">Actualizado por</label>
                  <ng-select
                    [items]="updatedUser$ | async"
                    bindLabel="label"
                    bindValue="value"
                    class="mb-2"
                    formControlName="updated_by"
                    placeholder="Selecciona una opción"
                  ></ng-select>
                </div>
                @if (this.profile?.id_rol !== roles.AGENT) {
                <div class="col-sm-4">
                  <label class="col-form-label">Tipo de usuario</label>

                  <ng-select
                    [items]="optionTypeUser$ | async"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="type_user"
                    class="mb-2"
                    placeholder="Selecciona una opción"
                  >
                  </ng-select>
                </div>
                }
                <div class="col-sm-4">
                  <label class="col-form-label">Estado</label>
                  <ng-select
                    [items]="optionsState$ | async"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="state"
                    class="mb-2"
                    placeholder="Selecciona un estado"
                  ></ng-select>
                </div>
              </div>
            </div>
            }
          </form>
          <!-- <hr class="mt-2 mb-3" /> -->
          <div class="text-end">
            <button
              type="button"
              class="btn btn-sm btn-restore me-1"
              (click)="resetForm()"
            >
              Restablecer
            </button>
            <button (click)="sendFilter()" class="btn btn-sm btn-head me-1">
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
