export enum ApiPathEnum {
  Auth = '/auth',
  User = '/user',
  Player = '/player',
  UserLevel = '/user-level',
  Audit = '/audit',
  State = '/state',
  Bank = '/bank',
  AccountingAccount = '/accounting-account',
  Country = '/country',
  Affiliate = '/affiliate',
  Module = '/module',
  Permission = '/permission',
  City = '/city',
  Province = '/province',
  GroupBankAccount = '/group-bank-account',
  HolderAccountHolder = '/holder-bank',
  Financial = '/financial',
  ChargesWithdrawalsRequest = '/charges-withdrawals-request',
  ChargesWithdrawals = '/charges-withdrawals',
  Closure = '/closure',
  SubscriptionPayment = '/subscription-payment',
  PageBalance = '/page-balance',
  CodeBonus = '/bonus-code',
  BalanceBonus = '/balance-bonus',
  HistoryBot = '/history-bot',
  Bot = '/bot',
  Category = '/category',
  Roles = '/role',
  Payroll = '/payroll',
  PaymentPayroll = '/payment-payroll',
  CodePhone = '/cell-phone-code',
  Debt = '/debtlist',
  PermissionsRole = '/permissions-roles',
  ReportBank = '/report',
}

export enum AuthEndpointEnum {
  Login = '/signIn',
}
export enum AuthMobileEndpointEnum {
  Login = '/log-in/mobile',
}

export enum PlayerEndpointEnum {
  GetPlayersData = '/get-all',
  GetRegisteredPlayersByDate = '/get-registered-players-by-date',
  CreatePlayer = '/create',
  UpdatePlayer = '/update-player',
  DeactivatePlayer = '/delete-player',
  GetAllData = '/get-all-data',
}

export enum BotEndpointEnum {
  GetAllActionsData = '/get-all-actions',
  SaveAction = '/save',
}

export enum AffiliateEndpointEnum {
  AffiliateInfoData = '/affiliate-info',
}
export enum ModuleEndpointEnum {
  GetAllModule = '/get-all',
  GetAllModulesWithPermissions = '/modules-permissions',
  CreateModule = '/create',
  UpdateModule = '/update',
  UpdateModuleStatus = '/update/:id/status',
}
export enum PermissionEndpointEnum {
  GetByPrincipalId = '/get-by-principal-id',
  CreatePermission = '/create',
  UpdatePermission = '/update',
  UpdatePermissionStatus = '/update/:id/status',
}
export enum RoleEndpointEnum {
  GetAllRoles = '/get-all',
  CreateRole = '/create',
  UpdateRole = '/update',
  UpdateRoleStatus = '/update/:id/status',
}

export enum PermissionsRoleEndpointEnum {
  GetAllPermissionsRole = '/get-all',
  CreatePermissionRole = '/create',
  UpdatePermissionsRole = '/update',
  DeletePermissionsRole = '/delete',
}

export enum ReportBankEndpointEnum {
  GetBankReport = '/get-bank-report',
}

export enum HistoryBotEndpointEnum {
  GetAllHistoryBotData = '/get-all',
  ValidateSuscriptionsPayments = '/validate-suscriptions-payments',
  DownloadHistoryBotData = '/download-all-sp-data',
  SetState = '/set-change-status',
}

export enum HolderBankEndpointEnum {
  GetHoldersAccountBankData = '/get-all-holders',
  GetAllBankAccounts = '/get-all-bank-accounts',
}

export enum UserEndpointEnum {
  GetAllUser = '/get-all',
  GetAllAgents = '/get-all-agents',
  GetAllAgentNames = '/get-name-agent',
  GetAllAffiliates = '/get-all-affiliates',
  GetAllHolders = '/get-all-holders',
  GetUserById = '/get-user-by-id',
  GetUserByTokenApp = '/get-user-token-app',
  GetUserType = '/get-userlevel-by-id',
  GenerateTwoFactorData = '/generate-two-factor-data',
  ActivateTwoFactorAuth = '/activate-two-factor-auth',
  DesactivateTwoFactorAuth = '/desactivate-two-factor-auth',
  VerifyLogin = '/verify-login',
  UpdateProfile = '/update-user',
  ChangePassword = '/set-password',
  GetAuthorizeUsers = '/authorized',
  GetAgentId = '/agentId',
  GetDecisionsAuthorizedUsers = '/decisions',
  GetUsersByType = '/get-all-by-type',
  GetAllReferredAndAgents = '/get-all-referred-and-agents',
  GetAllCellPhoneCodes = '/get-all',
}

export enum AuditEndpointEnum {
  GetAuditData = '/get-all',
  GetAllScripts = '/get-all-scripts',
  GetAllActions = '/get-all-actions',
  GetAllTables = '/get-all-tables',
  getAllUsers = '/get-all-users',
}
export enum DebtListEndpointEnum {
  GetDebtListData = '/get-all',
  CreateDebt = '/create',
  DesactivateDebt = '/delete-debt',
  GetFilteredStates = '/filtered-states',
  UpdateStatus = '/update-status',
}
export enum BalanceBonusEndpointEnum {
  CreateBalanceBonus = '/create',
  DownloadBalanceBonus = '/download-all-balances-bonus-data',
  GetBalanceBonusData = '/get-all',
  GetUserCreatedBonus = '/get-user-create-bonus',
  DesactivatedBonus = '/delete-balance-bonus',
}
export enum StateEndpointEnum {
  GetStateData = '/get-all',
  GetStateSpData = '/get-all-sp',
  GetStateLwData = '/get-all-lw',
}

export enum ChargesWithdrawalsRequestEnum {
  GetChargesWithdrawals = '/get-all',
  CreateRequest = '/create',
  SetRequest = '/set-request',
  SetState = '/set-change-status',
  CreateReverse = '/set-request-reverse',
  CreateDivision = '/create-request-divide',
  GET_IMG_ENDPOINT = '/get-img',
  GET_CW = '/get-cw',
  GetPlayerTransactionsPerAgent = '/get-player-transactions-per-agent',
  getUserCreatedRequest = '/get-user-create-registier',
}

export enum ChargesWithdrawalsEnum {
  GetAllLw = '/get-all',
  GetUserEdiLw = '/get-user-edit-lw',
  DownloadLwData = '/download-all-lw-data',
  SetState = '/set-change-status',
  GetCategories = '/get-all',
}
export enum ClosureEnum {
  ReportDaily = '/daily',
  ReportDailySpecific = '/daily-specific',
  GetNote = '/get-note',
  GetClosure = '/get-closure',
  SendClosure = '/send-closure',
  UpdateNote = '/update-note',
  CreateNote = '/create-note',
}

export enum BankEndpointEnum {
  GetBankData = '/get-all',
  GetAllBanksBySelect = '/get-banks-select',
  CreateBank = '/create',
  UpdateBankState = '/update-status',
}

export enum PayrollEndPointEnum {
  GetPayrollData = '/get-all',
  CreatePayroll = '/create-payroll',
  UpdatePayrollValues = '/update-payroll-values',
}

export enum PaymentPayrollEndPointEnum {
  GetPaymentPayrollData = '/get-all',
  CreatePaymentPayroll = '/create-payment-payroll',
  UpdatePaymentPayrollValues = '/update-payment-payroll-values',
}

export enum AccountingAccountEndpointEnum {
  GetAccountingAccountData = '/get-all',
  GetAllData = '/get-all-account',
  CreateAccount = '/create-account',
  UpdateAccount = '/update-account',
  AccountFilter = '/account-filter',
}

export enum CountryEndPointEnum {
  GetCountryData = '/get-all',
  GetCountryById = '/get-country',
  GetCountryDataByFilter = '/get-all-by-fliter',
}
export enum ProvincesEndPointEnum {
  GetProvincesData = '/get-all',
  GetProvincesById = '/get-province',
}
export enum CityEndPointEnum {
  GetCityData = '/get-all',
  GetCityById = '/get-city',
}
export enum GroupBankAccountEndPointEnum {
  GetGroupBankAccountData = '/get-all',
  GetGroupBankAccountById = '/get-group-bank-account',
}
export enum FinancialEndPointEnum {
  PostNewSpend = '/create',
  GetFinancial = '/movements',
  GetFinancialOne = '/movement',
  GetLastInvoice = '/last-invoice',
  GetLastSpent = '/last-spent',
  GetLastFlashMovement = '/last-flash-movement',
  DeleteFinancial = '/delete',
  UpdateFinancialStatus = '/movement/:id/status',
}

export enum SubscriptionPaymentEndpointEnum {
  GetFinancialMovementSummary = '/get-financial-movements-summary',
  GetIncomeAndExpenses = '/get-income-and-expenses',
  GetBankAccounts = '/get-bank-accounts',
  GetAgentTransactionsSummary = '/get-agent-transactions-summary',
  GetExpensesByAccount = '/get-expenses-by-account',
  GetLatestExpenseMovements = '/latest-expenses-movements',
  GetAllSp = '/get-all',
  GetOneSp = '/get',
  GetUserEditSp = '/get-user-edit-sp',
  DownloadSpData = '/download-all-sp-data',
  SetState = '/set-change-status',
  updateItem = '/update-item',
}

export enum PageBalanceEndpointEnum {
  GetAllBalance = '/get-all-balance',
  CreateBalance = '/create-balance',
  UpdateBalance = '/update-balance',
  GetTotal = '/get-total',
  GetBalanceByDateAndAgent = '/get-balance-by-date-and-agent',
}

export enum CodeBonusEndpointEnum {
  GetAllBonusCode = '/get-all',
  UpdateStateBonusCode = '/update',
  GetAllTypeBonus = '/get-all-type-bonus',
  CreateBonusCodes = '/create',
  GetAgentBalance = '/balance',
}
export enum UserMainEndpointEnum {
  GetAllAgentsByUsers = '/get-all-agents-by-users',
  GetAllHoldersByUsers = '/get-all-holders-by-users',
  GetAllAdministrativesByUsers = '/get-all-admin-by-users',
  GetAllRoles = '/get-all',
}
