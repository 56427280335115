<div class="modal-header d-flex justify-content-between">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    class="bg-transparent border-0 fs-6"
    type="button"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fas fa-times" style="color: #000000"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container #dynamicComponentContainer></ng-container>
</div>
