import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AccountingAccountEndpointEnum,
  ApiPathEnum,
  BankEndpointEnum,
  CountryEndPointEnum,
  FinancialEndPointEnum,
} from '../constants/endpoints.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FinancialRegisterStatus } from '../interfaces/financial-register-status';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  public API_URL_BANK = `${environment.API_URL}${ApiPathEnum.Bank}`;
  public API_URL_ACCOUNTING_ACCOUNT = `${environment.API_URL}${ApiPathEnum.AccountingAccount}`;
  public API_URL_COUNTRY = `${environment.API_URL}${ApiPathEnum.Country}`;
  public API_URL_FINANCIAL = `${environment.API_URL}${ApiPathEnum.Financial}`;

  constructor(private http: HttpClient) { }

  getAllBankData(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_BANK}${BankEndpointEnum.GetBankData}`
    );
  }

  getAllAccountingAccountData(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_ACCOUNTING_ACCOUNT}${AccountingAccountEndpointEnum.GetAccountingAccountData}`
    );
  }

  getLastInvoiceNumber(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetLastInvoice}`
    );
  }

  getLastSpentNumber(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetLastSpent}`
    );
  }

  getLastFlashMovement(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetLastFlashMovement}`
    );
  }

  getAllCountries(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_COUNTRY}${CountryEndPointEnum.GetCountryData}`
    );
  }

  createSpend(formData: FormData): Observable<any> {
    return this.http.post(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.PostNewSpend}`,
      formData
    );
  }

  getFinancialMovements(
    page: number,
    limit: number,
    filters?: any
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    });

    return this.http.get(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetFinancial}`,
      { params }
    );
  }

  getFinancialMovement(id: string): Observable<any> {
    return this.http.get(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetFinancialOne + '/' + id
      }`
    );
  }

  getFinancialMovementDecisions(financialId: string): Observable<any> {
    return this.http.get(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.GetFinancialOne}/${financialId}/decisions`
    );
  }

  updateFinancialStatus(
    financialId: string,
    newState: number,
    reason: string,
    userId: number
  ): Observable<any> {
    const body = {
      financialId,
      newState,
      reason,
      userId,
    };

    return this.http.patch(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.UpdateFinancialStatus.replace(':id', financialId)}`,
      body
    );
  }

  deleteFinancialMovement(financialId: any): Observable<any> {
    return this.http.delete(
      `${this.API_URL_FINANCIAL}${FinancialEndPointEnum.DeleteFinancial}`,
      financialId
    );
  }
}
