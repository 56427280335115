<div class="container-fluid">
  <form
    class="theme-form mega-form"
    [formGroup]="cwForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-sm-12 col-xl-7 content-left">
        <div class="alert alert-warning" role="alert">
          <i class="far fa-clock" style="color: var(--alert-icon-color)"></i>
          Horario de atención de 06:00 a 00:00. Las solicitudes se procesaran
          dentro de este horario.
        </div>
        <div class="alert alert-warning" role="alert">
          <i class="fas fa-exclamation-circle" style="color: #f05656"></i> Los
          campos con * son obligatorios
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label>Tipo Transaccion *</label>
            <ng-select
              [items]="optionsTypeTransactions$ | async"
              bindLabel="label"
              bindValue="value"
              formControlName="tipo_transaccion"
              required=""
              (change)="onChangeTypeTransaction($event)"
              placeholder="Selecciona un tipo de transacción"
            >
            </ng-select>
            @if (cwForm.controls.tipo_transaccion!.touched &&
            cwForm.controls.tipo_transaccion.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Tipo de transacción es requerido
            </div>
            }
          </div>
          <div class="col-sm-6">
            <label>Monto *</label>
            <input
              #montoInput
              type="text"
              class="form-control custom-input"
              id="monto"
              required=""
              placeholder="Monto"
              (keyup)="validatorsAmountImg()"
              formControlName="monto"
              autocomplete="off"
              (input)="onInputChange($event)"
              validateDecimalNumber
            />
            <div
              *ngIf="
                cwForm.controls.monto!.touched &&
                cwForm.controls.monto.errors?.required
              "
              class="text-danger font-error mt-2"
            >
              Ingresa el monto de la boleta.
            </div>
            @if (amountError) {
            <div class="text-danger font-error mt-2">
              Asegura que el monto es igual a la boleta.
            </div>
            } @if (showAmountErrorMessage) {
            <div class="text-danger font-error mt-2">
              {{ amountErrorMessage }}
            </div>
            }
          </div>
        </div>
        <div class="row mt-3">
          @if (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
          ALLOWED_TYPES_FOR_BANK)) {
          <div class="col-sm-6 mt-1">
            <label>Banco Cliente *</label>
            <ng-select
              [items]="allClientBankData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_banco"
              placeholder="Selecciona un banco"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (open)="onSearch('')"
              (clear)="onSearchBank()"
              (change)="isInterbankTransaction ? onBankSelected($event) : null"
            >
            </ng-select>
            @if (cwForm.controls.id_banco!.touched &&
            cwForm.controls.id_banco.errors?.required) {
            <div class="text-danger font-error mt-2">El Banco es requerido</div>
            }
          </div>
          } @if (tipo_usuario === userType.AGENT_LEVEL_ONE) { @if
          (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
          ALLOWED_TYPES_FOR_AGENT_READONLY)) {
          <div class="col-sm-6">
            <label>Agente *</label>
            <input
              type="text"
              class="form-control custom-input"
              id="id_agente"
              required
              readonly
              placeholder="Agente"
              [value]="profile?.usuario"
            />
          </div>
          } @if (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
          ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL)) {
          <div class="col-xl-12">
            <label>Agente</label>
            <input
              type="text"
              class="form-control custom-input"
              id="id_agente"
              required
              readonly
              placeholder="Agente"
              [value]="profile?.usuario"
            />
          </div>
          } } @else { @if
          (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
          ALLOWED_TYPES_FOR_AGENT_PLAYER)) {
          <div class="col-sm-6">
            <div class="media">
              <label class="col-form-label m-r-10"
                >{{ isAgent ? "Agente" : "Jugador" }} *</label
              >
              <div class="media-body text-end switch-sm icon-state">
                <label class="switch">
                  <input type="checkbox" (change)="toggleSwitch()" />
                  <span class="switch-state"></span>
                </label>
              </div>
            </div>
            @if (isAgent) {
            <ng-select
              [items]="allAgentsData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_agente"
              placeholder="Selecciona un agente"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
              (change)="onSelectionChange()"
            ></ng-select>
            }@else {
            <ng-select
              [items]="allPlayersData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_jugador"
              placeholder="Selecciona un jugador"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
              (change)="onSelectionChange()"
            ></ng-select>
            } @if (isAgent) { @if (cwForm.controls.id_agente!.touched &&
            cwForm.controls.id_agente.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Agente es requerido
            </div>
            } }@else { @if (cwForm.controls.id_jugador!.touched &&
            cwForm.controls.id_jugador.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Jugador es requerido
            </div>
            } }
          </div>
          } @if (['6','7'].includes(cwForm.get('tipo_transaccion')?.value)) {
          <div class="col-xl-12">
            <div class="media">
              <label class="col-form-label m-r-10"
                >{{ isAgent ? "Agente" : "Jugador" }} *</label
              >
              <div class="media-body text-end switch-sm icon-state">
                <label class="switch">
                  <input type="checkbox" (change)="toggleSwitch()" />
                  <span class="switch-state"></span>
                </label>
              </div>
            </div>
            <ng-select
              [items]="isAgent ? allAgentsData : allPlayersData"
              bindLabel="label"
              bindValue="value"
              [formControlName]="isAgent ? 'id_agente' : 'id_jugador'"
              placeholder="{{
                isAgent ? 'Selecciona un agente' : 'Selecciona un jugador'
              }}"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
              (change)="onSelectionChange()"
            ></ng-select>
            @if (isAgent) { @if (cwForm.controls.id_agente!.touched &&
            cwForm.controls.id_agente.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Agente es requerido
            </div>
            } }@else { @if (cwForm.controls.id_jugador!.touched &&
            cwForm.controls.id_jugador.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Jugador es requerido
            </div>
            } }
          </div>
          } }
        </div>
        <div class="row mt-3">
          @if (tipo_usuario !== userType.AGENT_LEVEL_ONE) { @if
          (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
          ALLOWED_TYPES_FOR_HOLDER)) {
          <div class="col-sm-12">
            <label>Titular *</label>
            <ng-select
              [items]="allHoldersData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_titular"
              placeholder="Selecciona un titular"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
            ></ng-select>
            @if (cwForm.controls.id_titular!.touched &&
            cwForm.controls.id_titular.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Titular es requerido
            </div>
            }
          </div>
          } }
        </div>
        @if (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
        ALLOWED_TYPES_FOR_RECEIPT)) {
        <div class="row mt-3">
          <div class="col-sm-6">
            <label>Comprobante cliente *</label>
            <input
              type="text"
              class="form-control custom-input"
              id="comprobante"
              (keyup)="validatorsReciptImg()"
              required=""
              placeholder="Comprobante"
              formControlName="comprobante"
            />
            @if (cwForm.controls.comprobante!.touched &&
            cwForm.controls.comprobante.errors?.required) {
            <div class="text-danger font-error mt-2">
              Ingresa el comprobante de la boleta.
            </div>
            } @if (reciptError) {
            <div class="text-danger font-error mt-2">
              Asegura que el comprobante ingresado es igual a la boleta.
            </div>
            }
          </div>
          <div class="col-sm-6">
            <label>Transferencia Interbancaria</label>
            <ng-select
              [items]="optionsInterbankTransfer$ | async"
              bindLabel="label"
              bindValue="value"
              required=""
              formControlName="transferencia_interbancaria"
              placeholder="Selecciona una opción"
              (change)="isInterbankTransaction ? onBankSelected($event) : null"
            >
            </ng-select>
            @if (cwForm.controls.transferencia_interbancaria!.touched &&
            cwForm.controls.transferencia_interbancaria.errors?.required) {
            <div class="text-danger font-error mt-2">
              La transferencia interbancaria es requerida
            </div>
            }
          </div>
        </div>
        } @if (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
        ALLOWED_TYPES_FOR_IDENTIFICATION)) {
        <div class="row">
          <div class="col-sm-6 mt-1">
            <label>Identificación *</label>
            <input
              type="text"
              class="form-control custom-input"
              id="identificacion"
              required=""
              formControlName="identificacion"
              (keyup)="validateField($event, 'identificacion')"
            />
            @if (identificationError) {
            <div class="text-danger font-error mt-2">
              Solo se permite números, letras y guion como caracter especial.
            </div>
            } @if (cwForm.controls.identificacion!.touched &&
            cwForm.controls.identificacion.errors?.required) {
            <div class="text-danger font-error mt-2">
              La identificacion es requerida
            </div>
            }
          </div>
          <div class="col-sm-6">
            <div class="media align-items-center">
              <label class="col-form-label m-r-10">
                # Cuenta {{ accountTypeLabel }} *
              </label>
              <div class="media-body text-end switch-sm">
                <label class="switch">
                  <input
                    type="checkbox"
                    (change)="toggleAccountType($event)"
                    [checked]="isSaving"
                  />
                  <span class="switch-state"></span>
                </label>
              </div>
            </div>
            <input
              type="text"
              class="form-control custom-input"
              id="numero_cuenta"
              required=""
              formControlName="numero_cuenta"
              (keyup)="validateField($event, 'numero_cuenta')"
            />
            @if (numberAccountError) {
            <div class="text-danger font-error mt-2">
              Solo se permite números, letras y guion como caracter especial.
            </div>
            } @if (cwForm.controls.numero_cuenta.touched &&
            cwForm.controls.numero_cuenta.errors?.required) {
            <div class="text-danger font-error mt-2">
              El número de cuenta es requerido
            </div>
            }
          </div>
        </div>
        } @if(this.alloweEnterprise){
        <div class="row mt-3">
          <div class="col-sm-6 mt-1">
            <label>Banco Empresa *</label>
            <ng-select
              [items]="allEnterpriseBankData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_banco_empresa"
              placeholder="Selecciona un banco"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (open)="onSearch('')"
              (clear)="onSearchBank()"
              (change)="isInterbankTransaction ? onBankSelected($event) : null"
            >
            </ng-select>
          </div>
          <div class="col-sm-6">
            <label>Comprobante Empresa *</label>
            <input
              type="text"
              class="form-control custom-input"
              id="comprobante"
              placeholder="Comprobante"
              formControlName="comprobante_empresa"
            />
          </div>
        </div>
        } @if (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
        ALLOWED_TYPES_FOR_BENEFICIARY)) {
        <div class="row mt-3">
          <div class="col-sm-12">
            <label>Beneficiario</label>
            <input
              type="text"
              class="form-control custom-input"
              formControlName="nombre_titular"
            />
            @if (cwForm.controls.nombre_titular!.touched &&
            cwForm.controls.nombre_titular.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Beneficiario es requerido
            </div>
            }
          </div>
        </div>
        }
        <div class="row mt-3">
          <div class="col">
            <label>Detalle</label>
            <textarea
              formControlName="detalle"
              rows="3"
              class="form-control"
            ></textarea>
          </div>
        </div>
        @if (profile?.id_rol !== roles.AGENT) {
        <div class="mt-3">
          <div class="animate-chk">
            <div class="row">
              <div class="col">
                @if
                (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
                ALLOWED_TYPES_FOR_CHECK_RECEIPT) &&
                cwForm.get('tipo_transaccion')?.value !==
                TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()) { @if
                (!isRiskyTicket) {
                <label class="d-block" for="chk-ani">
                  <input
                    class="checkbox_animated"
                    id="chk-ani"
                    formControlName="estado_revisar_boleta"
                    (change)="handleCheckboxChange('estado_revisar_boleta')"
                    type="checkbox"
                  />
                  Revisar Boleta (No permite hacer carga directa)
                </label>
                }
                <label class="d-block" for="chk-ani1">
                  <input
                    class="checkbox_animated"
                    id="chk-ani1"
                    formControlName="permitirComprobante"
                    type="checkbox"
                    (change)="handleCheckboxChange('permitirComprobante')"
                  />
                  Permitir Ingresar Comprobante Repetido
                </label>
                } @if (!isRiskyTicket) { @if
                (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
                ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT) &&
                cwForm.get('tipo_transaccion')?.value !==
                TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()) { @if
                (cwForm.get('tipo_transaccion')?.value ===
                TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE ||
                cwForm.get('tipo_transaccion')?.value ===
                TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER ||
                cwForm.get('tipo_transaccion')?.value ===
                TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION) {
                <label class="d-block" for="chk-ani2">
                  <input
                    class="checkbox_animated"
                    id="chk-ani2"
                    type="checkbox"
                    formControlName="cargar_retirar_directo"
                    (change)="handleCheckboxChange('cargar_retirar_directo')"
                  />
                  Cargar o Retirar directo
                </label>
                } @else {
                <label class="d-block" for="chk-ani2">
                  <input
                    class="checkbox_animated"
                    id="chk-ani2"
                    type="checkbox"
                    formControlName="cargar_retirar_directo"
                    (change)="handleCheckboxChange('cargar_retirar_directo')"
                  />
                  Cargar o Retirar directo
                </label>
                } } } @if
                (isTransactionTypeAllowed(cwForm.get('tipo_transaccion')?.value,
                ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT)) {
                <label class="d-block" for="chk-ani3">
                  <input
                    class="checkbox_animated"
                    id="chk-ani3"
                    type="checkbox"
                    formControlName="estado_boleta_riesgosa"
                    (change)="handleCheckboxChange('estado_boleta_riesgosa')"
                  />
                  Boleta Riesgosa
                  @if(cwForm.get('estado_boleta_riesgosa')?.value) { @if
                  (inappropiatedComment) {
                  <span
                    class="fa fa-question-circle fa-sm"
                    style="color: #74c0fc"
                    [ngbTooltip]="
                      'Boleta no aceptada: Comentario inapropiado detectado ' +
                      inappropiatedComment +
                      ' ha sido marcada para la devolución'
                    "
                    tooltipClass="custom-tooltip"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    title="Tooltip text"
                  ></span>
                  }@else {
                  <span
                    class="fa fa-question-circle fa-sm"
                    style="color: #74c0fc"
                    [ngbTooltip]="'Boleta marcada para devolución'"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    tooltipClass="custom-tooltip"
                    title="Tooltip text"
                  ></span>
                  } }
                </label>
                } @if(![TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString(),
                TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString()].includes(cwForm.get('tipo_transaccion')?.value))
                {
                <label class="d-block" for="chk-ani6">
                  <input
                    class="checkbox_animated"
                    id="chk-ani6"
                    formControlName="estado_tiempo_espera"
                    type="checkbox"
                    (change)="handleCheckboxChange('estado_tiempo_espera')"
                  />
                  Transacción en espera
                  @if(cwForm.get('estado_tiempo_espera')?.value){
                  <span
                    class="fa fa-question-circle fa-sm text-info"
                    [ngbTooltip]="'La boleta estará en tiempo de espera'"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    tooltipClass="custom-tooltip"
                    title="Tooltip text"
                  ></span>
                  }
                </label>
                }
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="col-sm-12 col-xl-5 content-right">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="media-body text-end switch-sm icon-state">
              <label class="switch">
                <input
                  type="checkbox"
                  [checked]="isManual"
                  (change)="manualSwitch()"
                />
                <span class="switch-state"></span>
              </label>
            </div>
            <label class="col-form-label ms-2 mt-2">Agregar Manualmente</label>
          </div>
          @if(previewImage){ @for (alert of alerts; track $index) {
          <div class="mt-3">
            <div class="alert alert-danger" role="alert">
              {{ alert.message }}
            </div>
          </div>
          } }
          <div class="col-sm-12">
            <span>
              Sube el respectivo <a class="text-emphasis">comprobante</a> para
              completar el proceso.
            </span>
            <br />
            <div
              class="drop-area-container mt-3"
              (click)="triggerFileInput()"
              (dragover)="onDragOver($event)"
              (drop)="onDrop($event)"
            >
              <input
                type="file"
                class="file-input"
                required=""
                accept="image/png,image/jpeg"
                (change)="onFileSelected($event)"
                #fileInput
              />
              @if (!previewImage) {
              <div>
                <i class="fas fa-cloud-upload-alt uploadico"></i>
                <div class="drop-area mt-3 text-center bg-transparent">
                  Arrastra y suelta tu comprobante aquí <br />
                  o sube una
                  <a class="upload-link" (click)="triggerFileInput($event)"
                    ><strong>imagen</strong></a
                  >
                </div>
              </div>
              }@else {
              <div>
                <img
                  [src]="previewImage"
                  alt="Vista Previa"
                  class="cloud-image"
                />
              </div>
              }
            </div>
            @if (previewImage) {
            <div class="mt-2 text-center">
              <button class="secondary-button-sm" (click)="removeImage()">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-4" />
    <div class="text-end">
      <button
        type="button"
        class="primary-button-cancel me-1"
        (click)="closeModal()"
      >
        Cancelar
      </button>
      @if(isLoadingButton){
      <button class="primary-button" type="submit" disabled>
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
      }@else {
      <button
        type="submit"
        class="primary-button"
        [disabled]="!cwForm.valid || identificationError || numberAccountError"
      >
        Crear
      </button>
      }
    </div>
  </form>
</div>
