import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { SubscriptionPaymentService } from '../../../../../../../core/services/subscription-payment.service';
import { movementType } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { debounceTime, Observable, of, Subject } from 'rxjs';
import { UserService } from '../../../../../../../core/services/user.service';
import { HolderAccountBankService } from '../../../../../../../core/services/holder-account-bank.service';
import { UserMainService } from '../../../../../../user/services/user-main.service';
import {
  agentAfiliateAccountant,
  NG_SELECT_TYPE,
  userOnlyActive,
} from '../../../../../../../core/constants/constants';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FilterCommunicationService } from '../../../../../../../core/services/filter-communication.service';
import { FinancialService } from '../../../../../../../core/services/financial.service';
@Component({
  selector: 'app-edit-sp',
  templateUrl: './edit-sp.component.html',
  styleUrls: ['./edit-sp.component.scss'],
})
export class EditSpComponent {
  public NG_SELECT_TYPE = NG_SELECT_TYPE;
  public data: any;
  public spData: any;
  public id_country: any;
  public spEditForm!: FormGroup;
  public isLoadingButton = false;
  public isModified = false;
  public optionsMovementType$: any;
  public isLoading: boolean = true;
  public currentPageAgents = 1;
  public currentPageHolders = 1;
  public currentPageBanks = 1;
  public pageSize = 10;
  public optionsAgents$: any;
  public allAgentsData: any[] = [];
  public criteria: any;
  private holderSubject: Subject<{ term: string; type?: string }> =
    new Subject<{
      term: string;
      type?: string;
    }>();
  public transformedDataBank: any;
  public allBankData: any[] = [];
  public selectedBankId: any;
  public allHoldersData: any[] = [];
  public selectedFilter: any;
  public isAgent = true;
  public optionsAccountingAccount$!: Observable<string[]>;
  constructor(
    private _formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private _dynamicModal: DynamicModalService,
    private _spService: SubscriptionPaymentService,
    private _userService: UserService,
    private _holderAccountBank: HolderAccountBankService,
    private _userMainService: UserMainService,
    private _toastr: ToastrService,
    private _filterCommunicationService: FilterCommunicationService,
    private _financialService: FinancialService
  ) {
    this.initForm();
    this.holderSubject.pipe(debounceTime(400)).subscribe((data) => {
      this.allAgentsData = [];
      this.allBankData = [];
      this.allHoldersData = [];
      switch (data.type) {
        case NG_SELECT_TYPE.AGENT:
          this.loadAgents(data.term);
          break;
        case NG_SELECT_TYPE.BANK:
          this.getBankData(data.term);
          break;
        case NG_SELECT_TYPE.HOLDER:
          this.loadHolders(data.term);
          break;
      }
    });
  }

  ngOnInit(): void {
    const globalData = this._dynamicModal.getModalData();
    this.data = globalData.item;
    this.selectedFilter = globalData.filter;
    this.id_country = this.data.id_pais;
    this.selectedBankId = this.data.id_banco;
    this.getAccountingAccountData();
    this.getCwData();
  }

  onSubmit() {
    if (!this.spEditForm.valid) return;
    let { isAgent, fecha, ...rest } = this.spEditForm.value;
    fecha = moment(fecha).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    this._spService
      .updateItem({
        idabonos_pagos: this.data.idabonos_pagos,
        fecha,
        ...rest,
      })
      .subscribe({
        next: () => {
          this._toastr.success('Abono actualizado con exito');
          this._filterCommunicationService.changeFilter(this.selectedFilter);
          this.activeModal.close();
        },
        error: () => {
          this._toastr.error('Error al actualizar el abono');
        },
      });
  }

  updateIsModified() {
    this.isModified = true;
  }
  getCwData() {
    this.isLoading = true;
    this._spService.getSp(this.data.idabonos_pagos).subscribe((response) => {
      this.spData = response.data;
      this.loadAgents(this.data?.agent?.usuario);
      this.loadHolders(this.data?.spUserPerIdUsuTitular?.usuario);
      this.getBankData(this.data?.spBankPerIdBanco?.banco);
      this.getMovementType();
      this.updateData(this.spData);
      this.isLoading = false;
    });
  }

  initForm(): void {
    this.spEditForm = this._formBuilder.group({
      fecha: ['', Validators.required],
      id_movimiento: [{ value: null, disabled: true }],
      id_pais: [{ value: null, disabled: true }],
      id_usu_agente: [null],
      id_codigo: [null],
      isAgent: [false],
      n_comprobante: [{ value: '', disabled: true }],
      monto: [{ value: '', disabled: true }],
      id_banco: [null, Validators.required],
      id_usu_titular: [null, Validators.required],
      observacion: [{ value: '' }],
    });
  }

  updateData(data: any) {
    const { id_pais, fecha, ...rest } = data;
    this.spEditForm.patchValue({
      id_pais: this.data.spCountryPerIdPais?.pais,
      fecha: moment(fecha).utc().format('YYYY-MM-DD HH:mm:ss'),
      ...rest,
    });
    this.spEditForm
      .get('id_movimiento')
      ?.setValue(data.id_movimiento.toString());
    this.spEditForm.get('id_usu_titular')?.setValue(data.id_usu_titular);
    data?.id_codigo !== null
      ? ((this.isAgent = false),
        this.spEditForm.get('id_codigo')?.setValue(data.id_codigo),
        this.spEditForm.get('id_codigo')?.setValidators([Validators.required]),
        this.spEditForm.get('id_usu_agente')?.setValue(null))
      : ((this.isAgent = true),
        this.spEditForm.get('id_codigo')?.setValue(null),
        this.spEditForm
          .get('id_usu_agente')
          ?.setValidators([Validators.required]),
        this.spEditForm.get('id_usu_agente')?.setValue(data.id_usu_agente));
    this.spEditForm.get('id_banco')?.setValue(data.id_banco);
    this.spEditForm.get('isAgent')?.setValue(this.isAgent);
  }

  getMovementType() {
    this.optionsMovementType$ = of(movementType);
  }

  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country,
        userOnlyActive,
        agentAfiliateAccountant
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }

  onScrollToEnd(type: string) {
    this.currentPageAgents++;
    this.currentPageBanks++;
    this.currentPageHolders++;
    switch (type) {
      case NG_SELECT_TYPE.AGENT:
        this.loadAgents(this.criteria);
        break;
      case NG_SELECT_TYPE.BANK:
        this.getBankData(this.criteria);
        break;
      case NG_SELECT_TYPE.HOLDER:
        this.loadHolders(this.criteria);
        break;
      default:
        break;
    }
  }

  onSearch(term: string, type: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allBankData = [];
    this.allHoldersData = [];
    this.currentPageAgents = 1;
    this.currentPageHolders = 1;
    this.currentPageBanks = 1;
    this.holderSubject.next({ term, type });
  }

  loadHolders(searchTerm?: string) {
    this._holderAccountBank
      .getAllHoldersAccountData(
        this.currentPageHolders,
        this.pageSize,
        this.id_country,
        this.selectedBankId,
        searchTerm
      )
      .subscribe((res) => {
        const response = res.data;
        const transformedData = response.data.map(
          (item: {
            holder: any;
            id_usuario: any;
            bank: any;
            account_number: any;
            id_banco: any;
          }) => {
            return {
              label:
                item.holder +
                ' (' +
                item.bank +
                ' - ' +
                item.account_number +
                ')',
              value: item.id_usuario,
            };
          }
        );
        this.allHoldersData = [...this.allHoldersData, ...transformedData];
      });
  }

  getBankData(searchTerm?: string) {
    this._userMainService
      .getAllBanksBySelect(
        this.currentPageBanks,
        this.pageSize,
        searchTerm,
        this.id_country
      )
      .subscribe((response) => {
        this.transformedDataBank = response.data.map((item) => {
          return { label: item.bankName, value: item.bankId };
        });
        this.allBankData = [...this.allBankData, ...this.transformedDataBank];
      });
  }

  onBankSelected(event: any) {
    this.spEditForm.get('id_usu_titular')?.setValue(null);
    this.selectedBankId = this.spEditForm.get('id_banco')?.value;
  }

  closeModal() {
    this.activeModal.close();
    this.spEditForm.reset();
  }

  getAccountingAccountData() {
    this._financialService
      .getAllAccountingAccountData()
      .subscribe((response) => {
        const transformedData = response.data.flatMap(
          (item: {
            subcuentas: any[];
            codigo_cuenta: string;
            nombre_cuenta: any;
            id_codigos: any;
          }) => {
            if (item.subcuentas && item.subcuentas.length > 0) {
              return item.subcuentas
                .filter((subcuenta: any) =>
                  subcuenta.codigo_cuenta.startsWith('6')
                )
                .map((subcuenta: any) => {
                  const label = `${subcuenta.codigo_cuenta} - ${subcuenta.nombre_cuenta}`;
                  return { label: label, value: subcuenta.id_codigos };
                });
            } else if (item.codigo_cuenta.startsWith('6')) {
              const label = `${item.codigo_cuenta} - ${item.nombre_cuenta}`;
              return { label: label, value: item.id_codigos };
            } else {
              return [];
            }
          }
        );
        this.optionsAccountingAccount$ = of(transformedData);
      });
  }
  toggleSwitch() {
    const currentControlName = this.isAgent ? 'id_usu_agente' : 'id_codigo';
    const oppositeControlName = this.isAgent ? 'id_codigo' : 'id_usu_agente';
    this.spEditForm.get(currentControlName)?.reset();
    this.spEditForm.get(oppositeControlName)?.reset();
    this.spEditForm.get(currentControlName)?.clearValidators();
    this.spEditForm.get(currentControlName)?.updateValueAndValidity();
    this.spEditForm
      .get(oppositeControlName)
      ?.setValidators([Validators.required]);
    this.spEditForm.get(oppositeControlName)?.updateValueAndValidity();
    this.isAgent = !this.isAgent;
  }
}
