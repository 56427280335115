import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  of,
  switchMap,
} from 'rxjs';
import Swal from 'sweetalert2';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { FinancialService } from '../../../../../../../core/services/financial.service';
import { HolderAccountBankService } from '../../../../../../../core/services/holder-account-bank.service';
import { PlayerService } from '../../../../../../../core/services/player.service';
import { UserService } from '../../../../../../../core/services/user.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import moment from 'moment';
import {
  INTERBANK_TRANSDFER_OPTIONS,
  minimum_amount_ec,
  minimum_amount_pe,
  TRANSACTION_TYPES,
  ALLOWED_TYPES_FOR_BANK,
  ALLOWED_TYPES_FOR_AGENT_READONLY,
  ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL,
  ALLOWED_TYPES_FOR_AGENT_PLAYER,
  ALLOWED_TYPES_FOR_HOLDER,
  ALLOWED_TYPES_FOR_RECEIPT,
  ALLOWED_TYPES_FOR_IDENTIFICATION,
  ALLOWED_TYPES_FOR_BENEFICIARY,
  ALLOWED_TYPES_FOR_CHECK_RECEIPT,
  ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT,
  TRANSACTION_TYPE_VALUES,
  ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT,
  ALLOWED_TYPES_FOR_MONTO,
  ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT,
  ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT,
  INTERBANK_TRANSDFER_OPTIONS_VALUES,
  ACCOUNT_TYPES,
  PAGE_SIZE_VALUE,
  DEFAULT_VALUE,
  ACCOUNT_TYPES_TEXT,
  ALERTS_TYPES,
  TRUE_CHECK_VALUE,
  CURRENCY_VALUES,
} from '../../../../../../../shared/data/suscriptions-payments/constants';
import { OcrService } from '../../../../../../../shared/services/ocr.service';
import { ImageCompressionService } from '../../../../../../../shared/services/image-compression.service';
import {
  ROLES,
  USER_TYPES,
} from '../../../../../../../shared/data/config/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMainService } from '../../../../../../user/services/user-main.service';
import { TYPE_TRANSACTIONS_PERMISSIONS } from '../../../../../../../shared/data/config/constants-buttons';
import { userOnlyActive } from '../../../../../../../core/constants/constants';
interface Alert {
  type: string;
  message: string;
}

interface FilePreview {
  file: File;
  previewVisible: boolean;
  urlFile: string;
}

@Component({
  selector: 'app-create-cw-request',
  templateUrl: './create-cw-request.component.html',
  styleUrl: './create-cw-request.component.scss',
})
export class CreateCwRequestComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelect!: NgSelectComponent;
  @Input() defaultValue: any;
  @ViewChild('montoInput') montoInput!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  public optionsTypeTransactions$!: Observable<
    { value: string; label: string }[]
  >;
  public optionsAgents$!: Observable<string[]>;
  public optionsPlayers$!: Observable<string[]>;
  public optionsHolder$!: Observable<{ label: string; value: any }[]>;
  public optionsInterbankTransfer$!: Observable<
    { value: string; label: string }[]
  >;
  public isAgent = true;
  public isHolder = true;
  public loading = false;
  public cwForm!: FormGroup;
  public loadedItemCount = 0;
  public currentPageAgents = 1;
  public countAgents = 0;
  public currentPagePlayers = 1;
  public countPlayers = 0;
  public currentPageHolders = 1;
  public countHolders = 0;
  public pageSize = 10;
  public allAgentsData: any[] = [];
  public allHoldersData: any[] = [];
  public allPlayersData: any[] = [];
  public allClientBankData: any[] = [];
  public allEnterpriseBankData: any[] = [];
  public criteria: any;
  public previewImage: string | undefined;
  public transformedDataBank: any;
  public loadingImg = false;
  public loadingProgress = 0;
  public alerts: Alert[] = [];
  public selectedFiles: FilePreview[] = [];
  public idCodigo: number = 0;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public id!: number;
  public rol!: number;
  public receipVoucher: any;
  public amountVoucher: any;
  public amountError: boolean = false;
  public reciptError: boolean = false;
  public isManual = false;
  public valueManual!: boolean;
  public id_country!: any;
  public currentPageBanks = 1;
  public showAmountErrorMessage: boolean = false;
  public amountErrorMessage: string = '';
  public alloweEnterprise: boolean = false;
  public selectedBankId!: number;
  public selectedAccountNumber: any[] = [];
  public transfer_Type: any;
  public inappropiatedComment: any;
  public tipo_usuario: any;
  public isLoadingButton = false;
  private holderSubject: Subject<string> = new Subject<string>();
  public blackList: boolean = false;
  public entityType: string = '';
  public idusuario: number = 0;
  public isFileRequired: boolean = false;
  public isPaymentBoss: boolean = false;
  public isMobile: boolean = false;
  public token!: string;
  public roles = ROLES;
  public userType = USER_TYPES;
  public TRANSACTION_TYPE_VALUES = TRANSACTION_TYPE_VALUES;
  public ALLOWED_TYPES_FOR_BANK = ALLOWED_TYPES_FOR_BANK;
  public ALLOWED_TYPES_FOR_AGENT_READONLY = ALLOWED_TYPES_FOR_AGENT_READONLY;
  public ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL =
    ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL;
  public ALLOWED_TYPES_FOR_AGENT_PLAYER = ALLOWED_TYPES_FOR_AGENT_PLAYER;
  public ALLOWED_TYPES_FOR_HOLDER = ALLOWED_TYPES_FOR_HOLDER;
  public ALLOWED_TYPES_FOR_RECEIPT = ALLOWED_TYPES_FOR_RECEIPT;
  public ALLOWED_TYPES_FOR_IDENTIFICATION = ALLOWED_TYPES_FOR_IDENTIFICATION;
  public ALLOWED_TYPES_FOR_BENEFICIARY = ALLOWED_TYPES_FOR_BENEFICIARY;
  public ALLOWED_TYPES_FOR_CHECK_RECEIPT = ALLOWED_TYPES_FOR_CHECK_RECEIPT;
  public ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT =
    ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT;
  public ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT =
    ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT;
  public ALLOWED_TYPES_FOR_MONTO = ALLOWED_TYPES_FOR_MONTO;
  public ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT =
    ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT;
  public ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT =
    ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT;
  public excludeIdTitularConditions = [
    TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString(),
    TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION.toString(),
  ];
  public isSaving: boolean = true;
  public accountTypeLabel: string = 'Ahorros';
  public isRiskyTicket: boolean = false;
  public identificationError: boolean = false;
  public numberAccountError: boolean = false;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private _userMainService: UserMainService,
    private _userService: UserService,
    private _playerService: PlayerService,
    private ocrService: OcrService,
    private sanitizer: DomSanitizer,
    private _cwService: ChargesWithdrawalsRequestService,
    private dynamicModal: DynamicModalService,
    private _holderAccountBank: HolderAccountBankService,
    private _toastrSvc: ToastrService,
    private _imageCompressionService: ImageCompressionService,
    private activedRouter: ActivatedRoute,
    private router: Router
  ) {
    this.holderSubject.pipe(debounceTime(400)).subscribe((term: any) => {
      this.allAgentsData = [];
      this.allPlayersData = [];
      this.allHoldersData = [];
      this.allEnterpriseBankData = [];
      this.allClientBankData = [];
      if (this.isAgent) {
        this.loadAgents(term);
      } else {
        this.loadPlayers(term);
      }
      this.loadHolders(term);
      this.getBankData(term);
    });
  }

  async ngOnInit(): Promise<void> {
    await this.initForm();
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await this.loadTokenLogged();
    } else {
      await this.loadProfileLogged();
    }
    this.loadTypeTransfer();
    this.loadTypeTransactions();
    this.getBankData();
    this.loadAgents();
    this.loadPlayers();
    this.loadHolders();
    this.watchFormChanges();

    this.id_country = this.dynamicModal.getModalData();
    const isManualFromStorage = localStorage.getItem('isManual');
    this.isManual = isManualFromStorage === 'true';
    this.validatorsAmountImg();
    this.validatorsReciptImg();
  }

  //?LOAD DATA

  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            this.tipo_usuario = this.profile.tipo_usuario.toString();
            this.rol = this.profile.id_rol;
            // this.updateTransactionValidators(
            //   this.cwForm.get('tipo_transaccion')?.value,
            //   this.tipo_usuario
            // );
            if (this.tipo_usuario === USER_TYPES.AGENT_LEVEL_ONE) {
              this.cwForm.get('id_agente')?.setValue(this.profile.idusuario);
            }
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  async loadTokenLogged() {
    await new Promise<void>((resolve, reject) => {
      const fullUrl = this.router.url;
      const lastSlashIndex = fullUrl.lastIndexOf('/');
      const queryParamIndex = fullUrl.indexOf('?');
      if (queryParamIndex !== -1 && queryParamIndex > lastSlashIndex) {
        this.token = fullUrl.substring(lastSlashIndex + 1, queryParamIndex);
      } else {
        this.token = fullUrl.substring(lastSlashIndex + 1);
      }
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this.profile = res.data;
            this.tipo_usuario = this.profile.tipo_usuario.toString();
            this.rol = this.profile.id_rol;

            this.updateTransactionValidators(
              this.cwForm.get('tipo_transaccion')?.value,
              this.tipo_usuario
            );
            if (this.tipo_usuario === USER_TYPES.AGENT_LEVEL_ONE) {
              this.cwForm.get('id_agente')?.setValue(this.profile.idusuario);
            }
            resolve();
          } else {
            reject(new Error('No user id found'));
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getBankData(searchTerm?: string) {
    this.id_country = this.dynamicModal.getModalData();
    this._userMainService
      .getAllBanksBySelect(
        this.currentPageBanks,
        this.pageSize,
        searchTerm,
        this.id_country
      )
      .subscribe((response) => {
        this.transformedDataBank = response.data.map((item) => {
          return { label: item.bankName, value: item.bankId };
        });
        this.allEnterpriseBankData = [
          ...this.allEnterpriseBankData,
          ...this.transformedDataBank,
        ];
        this.allClientBankData = [
          ...this.allClientBankData,
          ...this.transformedDataBank,
        ];
      });
  }

  onBankSelected(event: { value: any }) {
    this.selectedBankId = event.value;

    // Verificar si la transferencia es interbancaria (YES u OTHERS)
    if (this.isInterbankTransaction) {
      const interbankTransferType = this.cwForm.get(
        'transferencia_interbancaria'
      )?.value;

      // Si la transferencia es interbancaria y es YES u OTHERS, tomar el banco empresa y no resetear titular
      if (
        interbankTransferType ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() ||
        interbankTransferType ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
      ) {
        this.selectedBankId =
          this.cwForm.get('id_banco_empresa')?.value || this.selectedBankId;

        // No resetear el titular
      } else if (
        interbankTransferType ===
        INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString()
      ) {
        // Si es NO, tomar el banco cliente y resetear el titular
        this.selectedBankId = this.cwForm.get('id_banco')?.value;
        this.cwForm.get('id_titular')?.setValue(null); // Resetear titular solo si es NO
      }
    } else {
      // Si no es una transferencia interbancaria, tomar el banco cliente y resetear el titular
      if (this.cwForm.get('id_banco')?.value) {
        this.selectedBankId = this.cwForm.get('id_banco')?.value;
        this.cwForm.get('id_titular')?.setValue(null); // Resetear titular
      }
    }

    // Habilitar el select de titular solo si hay un banco seleccionado
    if (this.selectedBankId) {
      this.cwForm.get('id_titular')?.enable();
      this.loadHolders();
    } else {
      this.cwForm.get('id_titular')?.disable();
    }
  }

  hasPermission(permissionId: number): boolean {
    return this.profile?.rol?.permisos_principales.some(
      (permisoPrincipal: any) =>
        permisoPrincipal.permisos.some(
          (permiso: any) => permiso.id === permissionId
        )
    );
  }

  loadTypeTransactions() {
    this.isRiskyTicket = this.cwForm.get('estado_boleta_riesgosa')?.value;

    let filteredTransactionTypes;

    if (this.isRiskyTicket) {
      filteredTransactionTypes = TRANSACTION_TYPES.filter(
        (item) =>
          item.value === TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString() ||
          item.value ===
            TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()
      );
    } else {
      let baseFilteredTransactionTypes = TRANSACTION_TYPES.filter(
        (item) => item.value !== TRANSACTION_TYPE_VALUES.FINANCIAL.toString()
      );

      if (this.isAgent) {
        filteredTransactionTypes = baseFilteredTransactionTypes.filter(
          (item) =>
            item.value !==
              TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString() &&
            item.value !==
              TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()
        );
      } else {
        filteredTransactionTypes = baseFilteredTransactionTypes.filter(
          (item) =>
            item.value === TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString() ||
            item.value ===
              TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString() ||
            item.value === TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT.toString()
        );
      }
    }

    filteredTransactionTypes = filteredTransactionTypes.filter((item) => {
      if (
        item.value ===
          TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString() &&
        !this.hasPermission(
          TYPE_TRANSACTIONS_PERMISSIONS.RISKY_WITHDRAWAL_TICKET
        )
      ) {
        return false;
      }
      if (
        item.value === TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString() &&
        !this.hasPermission(TYPE_TRANSACTIONS_PERMISSIONS.DEPOSIT_CREDIT)
      ) {
        return false;
      }
      if (
        item.value === TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString() &&
        !this.hasPermission(TYPE_TRANSACTIONS_PERMISSIONS.WITHDRAWAL_CREDIT)
      ) {
        return false;
      }
      return true;
    });

    this.optionsTypeTransactions$ = of(filteredTransactionTypes);

    if (!this.isRiskyTicket) {
      this.cwForm
        .get('tipo_transaccion')
        ?.setValue(TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString());
    }
  }

  onTransactionTypeChange() {
    const transactionType = this.cwForm.get('tipo_transaccion')?.value;
    if (
      transactionType !== TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString() &&
      transactionType !== TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString()
    ) {
      this.loadTypeTransactions();
    }
  }

  loadTypeTransfer() {
    this.optionsInterbankTransfer$ = of(INTERBANK_TRANSDFER_OPTIONS);
    this.cwForm
      .get('transferencia_interbancaria')
      ?.setValue(INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString());
  }

  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country,
        userOnlyActive
      )
      .pipe(
        distinctUntilChanged(),
        switchMap((response) => {
          const transformedData = response.data.map((item: any) => ({
            label: item.usuario,
            value: item.idusuario,
            whiteList: item.agente_white_list?.lista_negra,
          }));
          const uniqueData = transformedData.filter(
            (newItem: any) =>
              !this.allAgentsData.some(
                (existingItem) => existingItem.value === newItem.value
              )
          );
          this.allAgentsData = [...this.allAgentsData, ...uniqueData];
          return of(this.allAgentsData);
        })
      )
      .subscribe((data) => {
        this.optionsAgents$ = of(data);
      });
  }

  loadPlayers(searchTerm?: string) {
    this._playerService
      .getPlayers(
        this.id_country,
        this.currentPagePlayers,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map((item: any) => {
          return {
            label: item.usuario_jugador,
            value: item.id_jugador,
            whiteList: item.jugador_white_list?.lista_negra,
            id_usuario: item.id_usuario,
          };
        });
        this.optionsPlayers$ = of(transformedData);
        this.allPlayersData = [...this.allPlayersData, ...transformedData];
      });
  }

  loadHolders(searchTerm?: string) {
    this._holderAccountBank
      .getAllHoldersAccountData(
        this.currentPageAgents,
        this.pageSize,
        this.id_country,
        this.selectedBankId,
        searchTerm
      )
      .subscribe((res) => {
        const response = res.data;
        const transformedData = response.data.map(
          (item: {
            holder: any;
            id_usuario: any;
            bank: any;
            account_number: any;
            id_banco: any;
          }) => {
            this.selectedAccountNumber.push({
              value: item.account_number,
              id: item.id_usuario,
              bankId: item.id_banco,
            });

            return {
              label:
                item.holder +
                ' (' +
                item.bank +
                ' - ' +
                item.account_number +
                ')',
              value: item.id_usuario,
            };
          }
        );
        this.optionsHolder$ = of(transformedData);
        this.allHoldersData = [...this.allHoldersData, ...transformedData];
      });
  }

  //*NG-SELECT
  onScrollToEnd() {
    if (this.isAgent) {
      this.currentPageAgents++;
      if (this.isAgent) {
        this.loadAgents(this.criteria);
      } else {
        this.loadAgents();
      }
    } else {
      this.currentPagePlayers++;
      if (!this.isAgent) {
        this.loadPlayers(this.criteria);
      } else {
        this.loadPlayers();
      }
    }
    this.currentPageHolders++;
    this.currentPageBanks++;
    this.getBankData(this.criteria);
    this.loadHolders(this.criteria);
  }

  onSearch(term: string) {
    this.criteria = term.trim();
    this.allAgentsData = [];
    this.allPlayersData = [];
    this.allHoldersData = [];
    this.allClientBankData = [];
    this.allEnterpriseBankData = [];
    this.currentPageAgents = PAGE_SIZE_VALUE;
    this.currentPagePlayers = PAGE_SIZE_VALUE;
    this.currentPageHolders = PAGE_SIZE_VALUE;
    this.currentPageBanks = PAGE_SIZE_VALUE;
    this.holderSubject.next(term);
  }
  onSearchBank() {
    this.selectedBankId = DEFAULT_VALUE;
  }

  //?MODAL
  closeModal() {
    this.activeModal.close();
    this.cwForm.reset();
  }

  //SWITCHES
  toggleSwitch() {
    const currentControlName = this.isAgent ? 'id_agente' : 'id_jugador';
    const oppositeControlName = this.isAgent ? 'id_jugador' : 'id_agente';

    this.cwForm.get(currentControlName)?.setValue(null);

    this.cwForm.get(oppositeControlName)?.setValue(null);

    this.isAgent = !this.isAgent;
    this.loadTypeTransactions();
  }

  manualSwitch() {
    this.isManual = !this.isManual;
    this.amountError = false;
    this.reciptError = false;
    this.validatorsAmountImg();
    this.validatorsReciptImg();
    localStorage.setItem('isManual', String(this.isManual));
  }

  toggleAccountType(event: any): void {
    this.isSaving = !this.isSaving;
    this.accountTypeLabel = this.isSaving
      ? ACCOUNT_TYPES_TEXT.SAVINGS
      : ACCOUNT_TYPES_TEXT.CURRENT;
  }

  //*!ALERTS
  clearDangerAlerts(): void {
    this.alerts = this.alerts.filter(
      (alert) => alert.type !== ALERTS_TYPES.DANGER
    );
  }

  showDangerAlert(message: string): void {
    this.alerts.push({ type: ALERTS_TYPES.DANGER, message: message });
  }

  closeAlert(alert: Alert): void {
    this.alerts.splice(this.alerts.indexOf(alert), TRUE_CHECK_VALUE);
  }

  watchFormChanges(): void {
    this.cwForm.get('comprobante')?.valueChanges.subscribe((val) => {
      if (val && this.cwForm.get('monto')?.value) {
        this.clearDangerAlerts();
      }
    });

    this.cwForm.get('tipo_transaccion')?.valueChanges.subscribe(() => {
      this.checkMinimumAmount();
    });

    this.cwForm.get('monto')?.valueChanges.subscribe((val) => {
      this.checkMinimumAmount();
      if (val && this.cwForm.get('comprobante')?.value) {
        this.clearDangerAlerts();
      }
    });
  }

  //*IMAGES
  async onFileSelected(event: any) {
    const file: File = event.target.files[DEFAULT_VALUE];

    if (file) {
      if (!file.type.startsWith('image/')) {
        this._toastrSvc.error('Solo se permiten imágenes en formato JPG o PNG');
        return;
      }

      try {
        const compressedFile =
          await this._imageCompressionService.compressImage(file);
        const url = this.fileUrl(compressedFile);

        this.selectedFiles = [
          {
            file: compressedFile,
            previewVisible: true,
            urlFile: url,
          },
        ];
        this.previewImage = url;
        this.processFile(compressedFile);
        event.target.value = '';
      } catch (error) {
        this._toastrSvc.error('Error al comprimir la imagen');
      }
    }
  }

  triggerFileInput(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.fileInput.nativeElement.click();
  }

  fileUrl(file: File): any {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async uploadFiles(files: FileList) {
    if (files.length > DEFAULT_VALUE) {
      const file = files[DEFAULT_VALUE];

      if (!file.type.startsWith('image/')) {
        this._toastrSvc.error('Solo se permiten imágenes en formato JPG o PNG');
        return;
      }

      try {
        const compressedFile =
          await this._imageCompressionService.compressImage(file);
        const url = this.fileUrl(compressedFile);

        this.selectedFiles = [
          {
            file: compressedFile,
            previewVisible: true,
            urlFile: url,
          },
        ];
        this.previewImage = url;
      } catch (error) {
        this._toastrSvc.error('Error al comprimir la imagen');
      }
    }
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    const files = $event.dataTransfer?.files;
    if (files && files.length > DEFAULT_VALUE) {
      this.uploadFiles(files);
      this.processFile(files[DEFAULT_VALUE]);
    }
  }

  onDragOver($event: DragEvent) {
    $event.preventDefault();
  }

  removeImage() {
    this.previewImage = undefined;
    this.selectedFiles = [];
  }

  processFile(file: File) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target) {
        const imageData = event.target.result as string;

        this.processImage(imageData);
        if (!this.isManual) {
          this.amountError = false;
          this.reciptError = false;
          this.cwForm.patchValue({
            comprobante: 'Procesando...',
            monto: 'Procesando...',
            detalle: 'Procesando...',
          });
          try {
            const receiptNumber = await this.ocrService.readReceiptNumber(
              imageData
            );
            this.receipVoucher = receiptNumber;
            this.cwForm.patchValue({ comprobante: receiptNumber });
          } catch {
            this.showDangerAlert('Error al leer el número de comprobante.');
            this.cwForm.patchValue({
              comprobante: null,
            });
          }
          try {
            const amount = await this.ocrService.readAmount(imageData);
            this.amountVoucher = amount;
            this.cwForm.patchValue({ monto: amount });
          } catch {
            this.showDangerAlert(
              'Error al leer el monto, ingresar manualmente.'
            );
            this.cwForm.patchValue({
              monto: null,
            });
          }
          try {
            const bankName = await this.ocrService.readBank(imageData);
            const bankControl = this.cwForm.get('id_banco');
            if (bankControl) {
              const bankOption = this.transformedDataBank.find(
                (option: any) => {
                  const cleanedBankName = bankName
                    .replace(/Banco\s*/i, '')
                    .trim();
                  return (
                    option.label.toLowerCase() === cleanedBankName.toLowerCase()
                  );
                }
              );
              if (bankOption) {
                bankControl.setValue(bankOption.value);
                this.onBankSelected({ value: bankOption.value });
              }
            }
          } catch {
            this.showDangerAlert('Error al leer el banco.');
            this.cwForm.patchValue({ id_banco: null });
          }
          try {
            const text = await this.ocrService.readImageText(imageData);
            this.inappropiatedComment = text;
            this.cwForm.get('detalle')?.patchValue(text);
            if (text) {
              this.cwForm.get('estado_boleta_riesgosa')?.patchValue(true);
              this.loadTypeTransactions();
              this.cwForm
                .get('tipo_transaccion')
                ?.setValue(
                  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString()
                );
            }
          } catch {
            this.showDangerAlert(
              'Error al leer el monto, ingresar manualmente.'
            );
            this.cwForm.patchValue({
              detalle: null,
            });
          }
        }
      }
    };
    reader.readAsDataURL(file);
  }

  processImage(imageData: string | ArrayBuffer | null) {
    if (imageData) {
      this.previewImage = imageData.toString();
    }
  }

  onSelectionChange() {
    const controlName = this.isAgent ? 'id_agente' : 'id_jugador';
    const oppositeControlName = this.isAgent ? 'id_jugador' : 'id_agente';
    const selectedValue = this.cwForm.get(controlName)?.value;

    this.cwForm.removeControl('id_personas');
    this.cwForm.addControl(
      controlName,
      this.fb.control(selectedValue, Validators.required)
    );
    this.cwForm.get(oppositeControlName)?.setValue(0);

    if (!selectedValue) {
      this.blackList = false;
      return;
    }

    const selectedItem = this.isAgent
      ? this.allAgentsData.find((agent) => agent.value === selectedValue)
      : this.allPlayersData.find((player) => player.value === selectedValue);

    this.idusuario = selectedItem?.id_usuario;
    this.blackList = selectedItem?.whiteList === TRUE_CHECK_VALUE;
    this.entityType = this.isAgent ? 'agente' : 'jugador';
  }

  //*FORM
  async initForm(): Promise<void> {
    this.createForm();
    await this.loadProfileBasedOnUrl();
    this.configureFormForPaymentsBoss();
    this.handleTransactionTypeChanges();
    this.handleTransferInterbankChanges();
    this.handleBankSelectionChanges();
    this.handleComprobanteChanges();
    this.enableTitularFieldOnBankSelection();
    this.cwForm
      .get('monto')
      ?.valueChanges.subscribe((value) => this.checkProcessingState());
    this.cwForm
      .get('comprobante')
      ?.valueChanges.subscribe((value) => this.checkProcessingState());
    this.cwForm
      .get('detalle')
      ?.valueChanges.subscribe((value) => this.checkProcessingState());
  }

  createForm(): void {
    this.cwForm = this.fb.group({
      tipo_transaccion: [null, Validators.required],
      monto: [null, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      id_banco: [null],
      id_banco_empresa: [null],
      id_titular: [{ value: null, disabled: true }],
      comprobante: [''],
      comprobante_empresa: [''],
      nombre_titular: [null],
      id_agente: [null],
      id_jugador: [null],
      transferencia_interbancaria: [null],
      detalle: [null],
      identificacion: [null],
      numero_cuenta: [null],
      estado_revisar_boleta: false,
      permitirComprobante: false,
      cargar_retirar_directo: false,
      estado_boleta_riesgosa: false,
      estado_tiempo_espera: false,
    });
  }
  async loadProfileBasedOnUrl(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await this.loadTokenLogged();
    } else {
      await this.loadProfileLogged();
    }
  }

  validateField(event: any, fieldName: string): void {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9-]*$/;

    if (fieldName === 'identificacion') {
      this.identificationError = !regex.test(value);
    }
    if (fieldName === 'numero_cuenta') {
      this.numberAccountError = !regex.test(value);
    }
  }

  configureFormForPaymentsBoss(): void {
    if (this.profile?.id_rol === ROLES.PAYMENTS_BOSS) {
      this.isPaymentBoss = true;
      this.cwForm.get('estado_boleta_riesgosa')?.setValue(true);
      this.cwForm
        .get('tipo_transaccion')
        ?.setValue(TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString());
      this.cwForm.get('tipo_transaccion')?.disable();
      this.cwForm.get('estado_boleta_riesgosa')?.disable();
    }
  }

  handleTransactionTypeChanges(): void {
    this.cwForm.get('tipo_transaccion')?.valueChanges.subscribe((value) => {
      this.updateTransactionValidators(value, this.tipo_usuario);
      this.updateFileRequirement(value);
    });

    this.cwForm.get('tipo_transaccion')?.valueChanges.subscribe(() => {
      this.cwForm
        .get('transferencia_interbancaria')
        ?.setValue(INTERBANK_TRANSDFER_OPTIONS_VALUES.NO);
      const transaction_type = this.cwForm.get('tipo_transaccion')?.value;
      if (
        transaction_type ===
          TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString() ||
        transaction_type ===
          TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION.toString()
      ) {
        this.cwForm.get('cargar_retirar_directo')?.setValue(true);
      } else {
        this.cwForm.get('cargar_retirar_directo')?.setValue(false);
      }
    });
  }

  handleTransferInterbankChanges(): void {
    this.cwForm
      .get('transferencia_interbancaria')
      ?.valueChanges.subscribe(() => {
        this.transfer_Type = this.cwForm.get(
          'transferencia_interbancaria'
        )?.value;
        this.alloweEnterprise =
          this.transfer_Type ===
            INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() ||
          this.transfer_Type ===
            INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString();
        this.cwForm.get('id_titular')?.setValue(null);
      });

    this.cwForm
      .get('transferencia_interbancaria')
      ?.valueChanges.subscribe(() => {
        const transferencia_interbancaria = this.cwForm.get(
          'transferencia_interbancaria'
        )?.value;
        if (
          transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString()
        ) {
          this.checkBankEquality();
        } else if (
          transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
        ) {
          this.checkReceiptEquality();
        }
      });
  }

  handleBankSelectionChanges(): void {
    this.cwForm.get('id_banco')?.valueChanges.subscribe((value) => {
      const transferencia_interbancaria = this.cwForm.get(
        'transferencia_interbancaria'
      )?.value;
      if (
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString() ||
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
      ) {
        this.cwForm.get('id_banco_empresa')?.setValue(value);
      }
    });
  }

  handleComprobanteChanges(): void {
    this.cwForm.get('comprobante')?.valueChanges.subscribe(() => {
      const transferencia_interbancaria = this.cwForm.get(
        'transferencia_interbancaria'
      )?.value;
      if (
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString() ||
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString()
      ) {
        const id_banco_empresa_value = this.cwForm
          .get('comprobante')
          ?.value?.trim();
        this.cwForm
          .get('comprobante_empresa')
          ?.setValue(id_banco_empresa_value);
      }
    });
  }

  enableTitularFieldOnBankSelection(): void {
    this.cwForm.get('id_banco')?.valueChanges.subscribe(() => {
      this.updateTitularFieldState();
    });

    this.cwForm.get('id_banco_empresa')?.valueChanges.subscribe(() => {
      this.updateTitularFieldState();
    });
  }

  updateTitularFieldState(): void {
    const bancoCliente = this.cwForm.get('id_banco')?.value;
    const bancoEmpresa = this.cwForm.get('id_banco_empresa')?.value;

    if (bancoCliente || bancoEmpresa) {
      // Si hay banco cliente o banco empresa seleccionado, habilitar el campo 'id_titular'
      this.cwForm.get('id_titular')?.enable();
    } else {
      // Si no hay ninguno seleccionado, deshabilitar el campo 'id_titular'
      this.cwForm.get('id_titular')?.disable();
      this.cwForm.get('id_titular')?.setValue(null);
    }
  }

  updateFileRequirement(transactionType: string): void {
    this.isFileRequired = transactionType === DEFAULT_VALUE.toString();
    this.cwForm.get('foto_comprobante')?.updateValueAndValidity();
  }

  checkBankEquality(): boolean {
    const transferencia_interbancaria = this.cwForm.get(
      'transferencia_interbancaria'
    )?.value;

    if (
      transferencia_interbancaria ===
      INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString()
    ) {
      const id_banco = this.cwForm.get('id_banco')?.value;
      const id_banco_empresa = this.cwForm.get('id_banco_empresa')?.value;

      if (id_banco === id_banco_empresa) {
        this._toastrSvc.error('Los bancos no pueden ser iguales!', '', {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        });
        return true;
      }
    }
    return false;
  }

  checkReceiptEquality(): boolean {
    const transferencia_interbancaria = this.cwForm.get(
      'transferencia_interbancaria'
    )?.value;

    if (
      transferencia_interbancaria ===
      INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
    ) {
      const comprobante = this.cwForm.get('comprobante')?.value;
      const comprobante_empresa = this.cwForm.get('comprobante_empresa')?.value;

      if (comprobante === comprobante_empresa) {
        this._toastrSvc.error('Los comprobantes no pueden ser iguales!', '', {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        });
        return true;
      }
    }
    return false;
  }

  handleConfirmationCwd() {
    const currentStatus = this.cwForm.get('cargar_retirar_directo')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Cargar o Retirar directo?'
        : '¿Estás seguro de desmarcar Cargar o Retirar directo?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwForm.get('cargar_retirar_directo');
        if (formControl) {
          formControl.setValue(currentStatus);
        }

        if (currentStatus) {
          this.cwForm.patchValue({
            estado_revisar_boleta: false,
            estado_boleta_riesgosa: false,
          });
        }
      } else {
        this.cwForm.patchValue({
          cargar_retirar_directo: !currentStatus,
        });
      }
    });
  }

  handleConfirmationRt() {
    const currentStatus = this.cwForm.get('estado_boleta_riesgosa')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Boleta Riesgosa?'
        : '¿Estás seguro de desmarcar Boleta Riesgosa?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwForm.get('estado_boleta_riesgosa');
        if (formControl) {
          formControl.setValue(currentStatus);
        }

        if (currentStatus) {
          this.cwForm.patchValue({
            estado_revisar_boleta: false,
            cargar_retirar_directo: false,
          });
          this.loadTypeTransactions();
          this.cwForm
            .get('tipo_transaccion')
            ?.setValue(TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString());
        } else {
          this.loadTypeTransactions();
          this.cwForm
            .get('tipo_transaccion')
            ?.setValue(TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString());
        }
      } else {
        this.cwForm.patchValue({
          estado_boleta_riesgosa: !currentStatus,
        });
      }
    });
  }

  handleConfirmationCr() {
    const currentStatus = this.cwForm.get('permitirComprobante')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Permitir Comporbante Repetido?'
        : '¿Estás seguro de desmarcar Permitir Comporbante Repetido?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwForm.get('permitirComprobante');
        if (formControl) {
          formControl.setValue(currentStatus);
        }
      } else {
        this.cwForm.patchValue({
          permitirComprobante: !currentStatus,
        });
      }
    });
  }

  handleConfirmationTe() {
    const currentStatus = this.cwForm.get('estado_tiempo_espera')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Transacción en espera?'
        : '¿Estás seguro de desmarcar Transacción en espera?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwForm.get('estado_tiempo_espera');
        if (formControl) {
          formControl.setValue(currentStatus);
        }
      } else {
        this.cwForm.patchValue({
          estado_tiempo_espera: !currentStatus,
        });
      }
    });
  }

  handleConfirmationRb() {
    const currentStatus = this.cwForm.get('estado_revisar_boleta')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Revisar boleta?'
        : '¿Estás seguro de desmarcar Revisar boleta?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwForm.get('estado_revisar_boleta');
        if (formControl) {
          formControl.setValue(currentStatus);
        }

        if (currentStatus) {
          this.cwForm.patchValue({
            estado_boleta_riesgosa: false,
            cargar_retirar_directo: false,
          });
        }
      } else {
        this.cwForm.patchValue({
          estado_revisar_boleta: !currentStatus,
        });
      }
    });
  }

  handleCheckboxChange(checkboxName: string) {
    if (checkboxName === 'cargar_retirar_directo') {
      this.handleConfirmationCwd();
    }
    if (checkboxName === 'estado_boleta_riesgosa') {
      this.handleConfirmationRt();
    }
    if (checkboxName === 'estado_revisar_boleta') {
      this.handleConfirmationRb();
    }
    if (checkboxName === 'permitirComprobante') {
      this.handleConfirmationCr();
    }
    if (checkboxName === 'estado_tiempo_espera') {
      this.handleConfirmationTe();
    } else {
      const formControl = this.cwForm.get(checkboxName);

      if (formControl) {
        // Desmarca los otros checkboxes si el checkbox actual se marca
        if (formControl.value) {
          if (checkboxName === 'estado_revisar_boleta') {
            this.cwForm.patchValue({
              cargar_retirar_directo: false,
              estado_boleta_riesgosa: false,
            });
          } else if (checkboxName === 'cargar_retirar_directo') {
            this.cwForm.patchValue({
              estado_revisar_boleta: false,
              estado_boleta_riesgosa: false,
            });
          } else if (checkboxName === 'estado_boleta_riesgosa') {
            this.cwForm.patchValue({
              estado_revisar_boleta: false,
              cargar_retirar_directo: false,
            });
          }
        }
      }
    }
  }

  convertBooleanFieldsToNumbers(data: any): any {
    const convertedData = { ...data };
    const fieldsToConvert = [
      'estado_revisar_boleta',
      'permitirComprobante',
      'cargar_retirar_directo',
      'estado_boleta_riesgosa',
      'estado_tiempo_espera',
      'estado_lista_negra',
    ];

    fieldsToConvert.forEach((field) => {
      if (convertedData[field] === true) {
        convertedData[field] = TRUE_CHECK_VALUE;
      } else {
        convertedData[field] = DEFAULT_VALUE;
      }
    });

    return convertedData;
  }

  onSubmit() {
    let cwRequestData = this.cwForm.value;
    this.markFormGroupTouched(this.cwForm);
    this.checkFormStatus();
    const transferencia_interbancaria = this.cwForm.get(
      'transferencia_interbancaria'
    )?.value;
    if (
      transferencia_interbancaria === INTERBANK_TRANSDFER_OPTIONS_VALUES.NO ||
      transferencia_interbancaria === INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS
    ) {
      const id_banco_value = this.cwForm.get('id_banco')?.value;
      this.cwForm.get('id_banco_empresa')?.setValue(id_banco_value);
    }
    if (this.checkBankEquality()) return;
    if (this.checkReceiptEquality()) return;

    if (this.cwForm.valid && !this.showAmountErrorMessage) {
      cwRequestData = this.convertBooleanFieldsToNumbers(cwRequestData);
      cwRequestData[this.isAgent ? 'id_jugador' : 'id_agente'] = this.isAgent
        ? DEFAULT_VALUE
        : this.idusuario;
      this.isLoadingButton = true;
      if (
        [
          TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString(),
          TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE.toString(),
          TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString(),
        ].includes(cwRequestData.tipo_transaccion) &&
        this.selectedFiles.length === DEFAULT_VALUE
      ) {
        if (
          cwRequestData.tipo_transaccion ===
          TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString()
        ) {
          this._toastrSvc.error(
            'La foto del comprobante es obligatoria para la transacción de Cargar Saldo.'
          );
        } else if (
          cwRequestData.tipo_transaccion ===
          TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE.toString()
        ) {
          this._toastrSvc.error(
            'La foto del comprobante es obligatoria para la transacción de Cargar Bono Saldo.'
          );
        } else {
          this._toastrSvc.error(
            'La foto del comprobante es obligatoria para la transacción de Boleta Riesgosa Carga.'
          );
        }
        this.isLoadingButton = false;
        return;
      }
      this.sendFormData(cwRequestData);
    } else {
      this._toastrSvc.error(
        'Rellena todo el formulario o verifica el monto mínimo!',
        '',
        {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        }
      );
      this.isLoadingButton = false;
    }
  }

  checkProcessingState() {
    const monto = this.cwForm.get('monto')?.value;
    const comprobante = this.cwForm.get('comprobante')?.value;
    const detalle = this.cwForm.get('detalle')?.value;

    // Si algún campo está en "Procesando...", deshabilitar el botón
    if (
      monto === 'Procesando...' ||
      comprobante === 'Procesando...' ||
      detalle === 'Procesando...'
    ) {
      this.isLoadingButton = true; // Deshabilita el botón
    } else {
      this.isLoadingButton = false; // Habilita el botón
    }
  }

  sendFormData(cwRequestData: any) {
    const formData = new FormData();
    const localDate = moment();

    const formattedDate =
      localDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + '+00:00';
    console.log('Formatted Date:', formattedDate);

    formData.append('creado', formattedDate);
    formData.append('actualizado', formattedDate);
    if (this.profile?.id_rol === ROLES.PAYMENTS_BOSS) {
      formData.append(
        'tipo_transaccion',
        TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()
      );
    } else {
      formData.append('tipo_transaccion', cwRequestData.tipo_transaccion);
    }
    formData.append('monto', cwRequestData.monto);
    if (
      [
        TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString(),
        TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString(),
        TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString(),
      ].includes(cwRequestData.tipo_transaccion)
    ) {
      formData.append('id_banco', DEFAULT_VALUE.toString());
      formData.append('id_banco_empresa', DEFAULT_VALUE.toString());
      formData.append('id_titular', DEFAULT_VALUE.toString());
    } else {
      formData.append('id_banco', cwRequestData.id_banco);

      if (
        [
          TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString(),
          TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE.toString(),
          TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT.toString(),
          TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString(),
        ].includes(cwRequestData.tipo_transaccion)
      ) {
        const isInterbankTransaction = [
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
        ].includes(cwRequestData.transferencia_interbancaria);
        if (isInterbankTransaction) {
          formData.append('id_banco_empresa', cwRequestData.id_banco_empresa);
        } else {
          formData.append('id_banco_empresa', cwRequestData.id_banco);
        }
      } else {
        const isWithdrawalOrCommission = [
          TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString(),
          TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION.toString(),
        ].includes(cwRequestData.tipo_transaccion);

        const isInterbankTransaction = [
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
        ].includes(cwRequestData.transferencia_interbancaria);

        if (
          isWithdrawalOrCommission ||
          !cwRequestData.id_banco_empresa ||
          !isInterbankTransaction
        ) {
          formData.append('id_banco_empresa', DEFAULT_VALUE.toString());
        } else {
          formData.append('id_banco_empresa', cwRequestData.id_banco_empresa);
        }
      }

      const shouldExcludeIdTitular =
        this.profile.id_rol === ROLES.AGENT ||
        this.excludeIdTitularConditions.includes(
          cwRequestData.tipo_transaccion
        );

      if (!shouldExcludeIdTitular) {
        formData.append('id_titular', cwRequestData.id_titular);
      }
    }

    formData.append('comprobante', (cwRequestData.comprobante || '').trim());
    if (
      [
        INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
        INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
      ].includes(cwRequestData.transferencia_interbancaria)
    ) {
      formData.append(
        'comprobante_empresa',
        (cwRequestData.comprobante_empresa || '').trim()
      );
    }
    const accountType = this.isSaving
      ? ACCOUNT_TYPES.SAVINGS
      : ACCOUNT_TYPES.CURRENT;
    formData.append('tipo_cuenta', accountType.toString());
    formData.append(
      'identificacion',
      (cwRequestData.identificacion || '').trim()
    );
    formData.append('detalle', cwRequestData.detalle);
    formData.append('nombre_titular', cwRequestData.nombre_titular);
    if (this.profile.id_rol === ROLES.AGENT) {
      formData.append('id_agente', this.profile.idusuario);
    } else {
      formData.append('id_agente', cwRequestData.id_agente);
    }
    formData.append('id_jugador', cwRequestData.id_jugador);
    formData.append('id_pais', this.id_country);
    formData.append(
      'transferencia_interbancaria',
      [
        TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString(),
        TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION.toString(),
        TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString(),
        TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString(),
        TRANSACTION_TYPE_VALUES.FINANCIAL.toString(),
      ].includes(cwRequestData.tipo_transaccion)
        ? '1'
        : cwRequestData.transferencia_interbancaria
    );
    formData.append(
      'estado_revisar_boleta',
      cwRequestData.estado_revisar_boleta
    );
    formData.append('estado_tiempo_espera', cwRequestData.estado_tiempo_espera);
    formData.append(
      'estado_lista_negra',
      this.blackList ? TRUE_CHECK_VALUE.toString() : DEFAULT_VALUE.toString()
    );
    formData.append(
      'permitir_comprobante_repetido',
      cwRequestData.permitirComprobante
    );
    formData.append(
      'cargar_retirar_directo',
      [
        TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString(),
        TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString(),
      ].includes(cwRequestData.tipo_transaccion)
        ? '1'
        : cwRequestData.cargar_retirar_directo
    );
    formData.append(
      'estado_boleta_riesgosa',
      this.profile?.id_rol === ROLES.PAYMENTS_BOSS
        ? TRUE_CHECK_VALUE.toString()
        : cwRequestData.estado_boleta_riesgosa
    );

    formData.append(
      'numero_cuenta',
      (cwRequestData.numero_cuenta || '').trim()
    );

    for (let file of this.selectedFiles) {
      formData.append('foto_comprobante', file.file, file.file.name);
    }

    this._toastrSvc.info('Procesando...', '', {
      timeOut: 500,
    });

    this._cwService.createRequest(formData).subscribe(
      (response) => {
        this.isLoadingButton = false;
        this.closeModal();
      },
      (err) => {
        this.isLoadingButton = false;
      }
    );
  }

  validatorsAmountImg() {
    const userEnteredAmount = this.cwForm.get('monto')?.value;
    const imageAmount = this.amountVoucher;
    if (userEnteredAmount !== imageAmount && !this.isManual) {
      this.amountError = true;
    } else {
      this.amountError = false;
    }
    this.checkMinimumAmount();
  }

  validatorsReciptImg() {
    const userEnteredReceipt = this.cwForm.get('comprobante')?.value;
    const imageReceipt = this.receipVoucher;
    if (userEnteredReceipt !== imageReceipt && !this.isManual) {
      this.reciptError = true;
    } else {
      this.reciptError = false;
    }
  }

  updateTransactionValidators(
    transactionType: string,
    tipo_usuario: string
  ): void {
    const validatorsRequired = Validators.required;
    const shouldExcludeIdTitular =
      this.excludeIdTitularConditions.includes(transactionType) ||
      this.profile.id_rol === ROLES.AGENT;

    this.clearValidators([
      'monto',
      'id_banco',
      'id_banco_empresa',
      'id_agente',
      'id_jugador',
      'id_titular',
      'comprobante',
      'comprobante_empresa',
      'nombre_titular',
      'identificacion',
      'numero_cuenta',
    ]);
    if (
      this.isTransactionTypeAllowed(
        transactionType,
        ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT
      )
    ) {
      this.setValidators(
        ['monto', 'id_banco', 'comprobante'],
        validatorsRequired
      );
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE) {
        const fields = ['id_agente', 'id_jugador'];
        if (!shouldExcludeIdTitular) {
          fields.push('id_titular');
        }
        this.setValidators(fields, validatorsRequired);
      }
    } else if (
      this.isTransactionTypeAllowed(
        transactionType,
        ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT
      )
    ) {
      this.setValidators(['monto'], validatorsRequired);
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE) {
        this.setValidators(['id_agente', 'id_jugador'], validatorsRequired);
      }
    } else if (
      this.isTransactionTypeAllowed(transactionType, ALLOWED_TYPES_FOR_MONTO)
    ) {
      this.setValidators(['monto'], validatorsRequired);
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE) {
        this.setValidators(['id_agente', 'id_jugador'], validatorsRequired);
      }
    }

    this.cwForm.updateValueAndValidity();
  }

  clearValidators(fields: string[]): void {
    fields.forEach((field) => {
      this.cwForm.get(field)?.clearValidators();
      this.cwForm.get(field)?.updateValueAndValidity();
    });
  }

  setValidators(fields: string[], validator: ValidatorFn): void {
    fields.forEach((field) => {
      this.cwForm.get(field)?.setValidators(validator);
      this.cwForm.get(field)?.updateValueAndValidity();
    });
  }

  checkFormStatus() {
    const invalidFields = [];
    const formControls = this.cwForm.controls;

    for (const name in formControls) {
      if (formControls[name].invalid) {
        invalidFields.push(name);
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  checkMinimumAmount() {
    const montoInputValue = this.cwForm.get('monto')?.value;
    const id_country = this.id_country;
    const selectedTransactionType = this.cwForm.get('tipo_transaccion')?.value;
    let minimumAmount: number;

    if (
      selectedTransactionType ===
      TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT.toString()
    ) {
      this.showAmountErrorMessage = false;
      this.amountErrorMessage = '';
      return;
    }

    if (id_country === CURRENCY_VALUES.ECU) {
      minimumAmount = minimum_amount_ec;
    } else if (id_country === CURRENCY_VALUES.PER) {
      minimumAmount = minimum_amount_pe;
    } else {
      minimumAmount = DEFAULT_VALUE;
    }

    if (parseFloat(montoInputValue) < minimumAmount) {
      this.showAmountErrorMessage = true;
      this.amountErrorMessage = `El monto mínimo es $${minimumAmount}`;
    } else {
      this.showAmountErrorMessage = false;
      this.amountErrorMessage = '';
    }
  }

  isBankSelected(): boolean {
    return !this.cwForm.get('id_banco')?.value;
  }

  isTransactionTypeAllowed(
    transactionType: string | number,
    allowedTypes: (string | number)[]
  ): boolean {
    return allowedTypes.map(String).includes(String(transactionType));
  }

  get isInterbankTransaction(): boolean {
    const validInterbankOptions = [
      INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
      INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
      INTERBANK_TRANSDFER_OPTIONS_VALUES.NO,
    ];
    return validInterbankOptions.includes(
      this.cwForm.get('transferencia_interbancaria')?.value
    );
  }

  onChangeTypeTransaction(event: any) {
    this.cwForm.reset();
    this.cwForm.patchValue({
      tipo_transaccion: event.value,
      transferencia_interbancaria: INTERBANK_TRANSDFER_OPTIONS_VALUES.NO,
    });
  }

  onInputChange(event: any) {
    let searchTerm = event.target.value || '';
    searchTerm = searchTerm.replace(/\s+/g, '');
    event.target.value = searchTerm;
  }
}
