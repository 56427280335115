export const REQUEST_WITHDRAWAL_BUTTON_PERMISSIONS = {
  NEW_REQUEST: 4,
  BOT_REQUEST: 7,
  BOT_REQUEST_PENDING_RECEIPT: 8,
  EDIT_REQUEST: 6,
  EDIT_CORRECTION: 36,
  REJECT_REQUEST: 5,
  DOWNLOAD_RECEIPT: 10,
  CORRECT_REQUEST: 73,
  SPLIT_REQUEST: 74,
  VIEW_DETAILS: 75,
  PREVALIDATED_PROCESSED: 76,
  REVERSE_REQUEST: 77,
  PROCESSED: 78,
  PAYOUT: 82,
};

export const FINANCIAL_BUTTON_PERMISSIONS = {
  FLASH_MOVEMENT: 120,
};

export const REQUEST_BONUS_BUTTON_PERMISSIONS = {
  NEW_BONUS_BALANCE: 27,
  NEW_BONUS_CODE: 29,
  VERIFY_BONUS_CODE: 30,
  SEND_WS_BONUS_CODE: 31,
  COPY_BONUS_CODE: 32,
  DELETE_BONUS_BALANCE: 71,
  DELETE_BONUS_CODE: 72,
  EXCEL_BONUS_BALANCE: 113,
  EXCEL_BONUS_CODE: 114,
};

// STATUS
export const REQUEST_STATUS = {
  PENDING: 0,
  BOT_REQUEST: 1,
  PREVALIDATED_PROCESSED: 2,
  REJECTED: 3,
  BOT_PENDING_RECEIPT: 4,
  PROCESSED: 5,
  BOT_PROBLEM: 6,
  VALIDATED_PROCESSED: 7,
  CORRECTION: 8,
  NOT_ENTERED: 9,
  REVERSED: 10,
  DIVIDED: 11,
  REJECTED_COMMENT: 12,
  BOT_REVERSED: 13,
};

//TYPE TRANSACTIONS
export const TYPE_TRANSACTIONS_PERMISSIONS = {
  RISKY_WITHDRAWAL_TICKET: 85,
  DEPOSIT_CREDIT: 86,
  WITHDRAWAL_CREDIT: 87,
};

// EXCELL BUTTONS
export const EXCELL_BUTTONS = {
  EXCEL_AGENTS: 88,
  EXCEL_HOLDERS: 89,
  EXCEL_ADMIN: 90,
  EXCEL_PLAYERS: 91,
};

export const LOADS_WITHDRAWAL_BUTTON_PERMISSIONS = {
  ACCTIONS: 95,
};

export const PAYMENTS_BUTTON_PERMISSIONS = {
  CANCELATE_PAYMENTS: 96,
  MORE_ACCTIONS: 97,
  EDIT_SP: 117,
  VERIFY_SP: 121,
};

export const USER_LIST_PERMISSIONS = {
  // Agentes (Agents)
  VIEW_AGENT_INFO_ICON: 20,
  CREATE_NEW_AGENTS: 21,
  EDIT_AGENT: 22,
  BLOCK_AGENTS: 23,
  CHANGE_AGENT_PASSWORD: 24,

  // Titulares (Holders)
  CREATE_NEW_HOLDER: 68,
  BLOCK_HOLDER: 69,
  EDIT_HOLDER: 98,
  VIEW_HOLDER_INFO_ICON: 99,

  // Administrativos (Administrative)
  CREATE_NEW_ADMINISTRATIVE: 101,
  EDIT_ADMINISTRATIVE: 102,
  BLOCK_ADMINISTRATIVE: 103,
  CHANGE_ADMINISTRATIVE_PASSWORD: 104,
  VIEW_ADMINISTRATIVE_INFO_ICON: 100,

  // Jugadores (Players)
  CREATE_NEW_PLAYERS: 107,
  EDIT_PLAYERS: 108,
  VIEW_PLAYER_INFO_ICON: 105,
  COPY_PLAYER_PASSWORD: 106,
};
