<div class="container-fluid basic_table">
  <div class="row">
    <div class="card mt-3">
      <div class="card-header">
        <div class="text-end">
          <button
            class="btn btn-sm btn-refresh me-3"
            [disabled]="isLoading"
            ngbTooltip="Recargar Tabla"
            tooltipClass="custom-tooltip"
            (click)="reloadTable()"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          @if (!isMobile) { @if (excelButtonAllowed) {
          <button
            class="btn btn-sm btn-head me-3"
            ngbTooltip="Descargar Información en .xls"
            tooltipClass="custom-tooltip"
            [disabled]="isLoading"
            (click)="exportToExcel()"
          >
            <i class="far fa-file-excel"></i>
          </button>
          } } @if (buttonAllowed) {
          <button
            class="btn btn-sm btn-head me-3"
            [disabled]="isLoading"
            (click)="openCreateBcFormModal()"
          >
            <i class="fa-solid fa-circle-plus"></i> Nuevo Bono
          </button>
          }
        </div>
      </div>
      @if (!(isMobile$|async)) {
      <div class="card-body custom-datatable noscroll">
        <!-- @if(collectionSize > 20){
          <custom-pagination
          [collectionSize]="collectionSize"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onLimitChange($event)"
          [isHeader]="true"
        ></custom-pagination>
        } -->
        <div class="table-responsive signal-table custom-datatable">
          <table class="table table-hover table-responsive uppercase-table">
            <thead>
              <tr>
                @for (column of columnsTable; track $index) {
                <th>{{ column.name }}</th>
                }
              </tr>
            </thead>
            @if (isLoading) {
            <tbody class="text-center">
              <tr>
                <td colspan="11">
                  <!-- Ajusta el colspan según el número de columnas -->
                  <div class="loader-box">
                    <div class="loader-16"></div>
                  </div>
                </td>
              </tr>
            </tbody>
            } @if (!isLoading) { @for (item of bonusCodeData; track $index) {
            <tbody>
              <tr (click)="onRowSelected(item)">
                <td>
                  @if (item.estado !== CODE_BONUS_STATUS.PENDING && item.estado
                  !== CODE_BONUS_STATUS.DELETED) {
                  <div class="btn-group">
                    @if (verifyButtonAllowed) {
                    <button
                      ngbTooltip="Ver Información Del Bono"
                      tooltipClass="custom-tooltip"
                      class="btn btn-sm mt-2 btn-info"
                      style="padding: 0.375rem 0.9rem"
                      (click)="viewDetailsBonusCode()"
                    >
                      <i class="fas fa-check-double"></i>
                    </button>
                    } @if (deleteButtonAllowed) {
                    <button
                      ngbTooltip="Eliminar Bono"
                      tooltipClass="custom-tooltip"
                      class="btn btn-sm mt-2 btn-danger"
                      style="padding: 0.375rem 0.9rem"
                      (click)="deleteBonusCode()"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    } @if (sendWsButtonAllowed) {
                    <a
                      class="btn btn-sm btn-success mt-2"
                      style="padding: 0.375rem 0.9rem"
                      ngbTooltip="Enviar código promocional Vía Whatsapp"
                      tooltipClass="custom-tooltip"
                      href="https://wa.me/?text=Saludos%2C%20tienes%20un%20bono%20pendiente%20por%20activar%20de%20{{
                        item.monto
                      }}%2C%20utiliza%20el%20código%3A%20*{{
                        item.codigo_promocional
                      }}*"
                      target="_blank"
                      (click)="editStateBonusCode()"
                    >
                      <i class="fab fa-whatsapp"></i>
                    </a>
                    } @if (copyButtonAllowed) {
                    <button
                      class="btn btn-sm btn-warning mt-2"
                      style="padding: 0.375rem 0.9rem"
                      type="button"
                      ngbTooltip="Copiar código promocional"
                      tooltipClass="custom-tooltip"
                      (click)="
                        copyPromotionalCode(item.monto, item.codigo_promocional)
                      "
                    >
                      <i class="fa-solid fa-copy"></i>
                    </button>
                    }
                  </div>
                  }
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fas fa-calendar-check me-3 informative-icon"></i>
                    <span>{{ formatFecha(item.fecha) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-code me-3 informative-icon"></i>
                    <span class="text-success">{{
                      item.codigo_promocional
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i
                      class="fas fa-file-invoice-dollar me-3 informative-icon"
                    ></i>
                    <span class="text-primary">
                      <strong
                        >{{ getCurrencySymbol(item.id_pais)
                        }}{{ item.monto }}</strong
                      >
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-comments me-3 informative-icon"></i>
                    <span>{{ item.comentario }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-comments me-3 informative-icon"></i>
                    <span>{{ item.detalle_tipo_bono }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fas fa-calendar-check me-3 informative-icon"></i>
                    <span>{{ formatFecha(item.fecha_creacion) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fas fa-user-tie me-3 informative-icon"></i>
                    <span>{{ item.agente }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-user-plus me-3 informative-icon"></i>
                    <span>{{ item.usuario }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span
                      [ngClass]="{
                        'text-danger':
                          item.estado === CODE_BONUS_STATUS.PENDING,
                        'text-warning':
                          item.estado === CODE_BONUS_STATUS.CREATED,
                        'text-success': item.estado === CODE_BONUS_STATUS.SENT
                      }"
                    >
                      {{ getStatusText(item.estado) }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            } }
          </table>
          @if(isLoading){
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
          </div>
          } @else {
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            @if (roleId == ROLES.AGENT) {
            <div class="total-monto-container text-end mt-3">
              Total: <strong>{{ total }}</strong>
            </div>
            } @else {
            <div class="total-monto-container mt-3">
              Total en Dólares: <strong>{{ totalDolares }}</strong>
            </div>
            <div class="total-monto-container text-end mt-3">
              Total en Soles: <strong>{{ totalSoles }}</strong>
            </div>
            }
          </div>
          }
        </div>
      </div>
      }@else{
      <div class="card-body">
        @if (isLoading) {
        <!-- Ajusta el colspan según el número de columnas -->
        <div class="loader-box">
          <div class="loader-16"></div>
        </div>
        } @else { @for (item of bonusCodeData; track $index) {
        <div class="card mb-3">
          <div class="card-body custom-card">
            <div
              class="card-more-button-holder"
              (click)="toggleMenu($index, item)"
            >
              <i class="fa-solid fa-ellipsis"></i>
            </div>
            <div
              class="card-c-holder"
              [ngClass]="{ openCard: cardExpanded[$index] }"
            >
              @for (column of columnsTable.slice(0, 10); track $index) { @if
              (column.value && column.value !== 'acciones' && column.value !==
              'codigo_promocional'&& column.value !== 'fecha_creacion') {
              <div class="geneKeyValue card-list-col">
                <p
                  role="heading"
                  aria-level="1"
                  class="geneKeyValue__label ellipsis-text"
                >
                  {{ column.name }}
                </p>
                <br />
                <p class="geneKeyValue__value ellipsis-text">
                  @switch (column.value) { @case ('estado') {
                  <span
                    [ngClass]="{
                      'status-inactive':
                        item.estado === CODE_BONUS_STATUS.PENDING,
                      'status-wait': item.estado === CODE_BONUS_STATUS.CREATED,
                      'status-active': item.estado === CODE_BONUS_STATUS.SENT
                    }"
                  >
                    {{
                      item.estado === CODE_BONUS_STATUS.CREATED
                        ? "Creado"
                        : item.estado === CODE_BONUS_STATUS.PENDING
                        ? "Pendiente"
                        : "Enviado"
                    }}
                  </span>
                  } @case ('codigo_promocional') { @if(item.codigo_promocional){
                  <span class="d-flex align-items-center">
                    <i
                      class="fa-solid fa-copy"
                      style="color: #aee2fb; cursor: pointer; margin-right: 8px"
                      (click)="
                        copyPromotionalCode(item.monto, item.codigo_promocional)
                      "
                    ></i>
                    {{ item.codigo_promocional }}
                  </span>
                  } } @default {
                  {{ item[column.value] }}
                  }}
                </p>
              </div>
              } } @for (column of columnsTable.slice(0, 10); track $index) { @if
              (column.value === 'codigo_promocional') {
              <div
                class="geneKeyValue card-list-col"
                style="grid-column: span 2; text-align: center"
              >
                <p
                  role="heading"
                  aria-level="1"
                  class="geneKeyValue__label ellipsis-text"
                  style="margin-right: 17px"
                >
                  Código Promocional
                </p>
                <br />
                <p class="geneKeyValue__value ellipsis-text">
                  @if (item.codigo_promocional) {
                  <span
                    class="d-flex align-items-center justify-content-center flex-column"
                  >
                    {{ item.codigo_promocional }}
                    @if (copyButtonAllowed) {
                    <button
                      class="btn btn-outline-info btn-sm mt-2"
                      (click)="
                        copyPromotionalCode(item.monto, item.codigo_promocional)
                      "
                    >
                      <i class="fa-solid fa-copy me-1"></i> Copiar Código
                      Promocional
                    </button>
                    }
                  </span>

                  } @else {
                  <button
                    class="btn btn-outline-secondary btn-sm mt-2"
                    disabled
                  >
                    Código No Disponible
                  </button>
                  }
                </p>
              </div>
              }}
            </div>
          </div>
          @if (showMenu && actualIndex === $index) {
          <div class="popover1">
            <ul>
              <li style="border-top: 1px solid var(--chart-border)">
                <div
                  class="d-flex align-items-center"
                  (click)="openSubMenu(actualItem)"
                >
                  <i class="fa-solid fa-eye me-3 informative-icon"></i>
                  <span>Ver Detalles</span>
                </div>
              </li>
              <li style="border-top: 1px solid var(--chart-border)">
                @if(item.estado == CODE_BONUS_STATUS.PENDING){
                <div
                  class="d-flex align-items-center mb-2"
                  (click)="viewDetailsBonusCode()"
                >
                  <i
                    class="fa-solid fa-triangle-exclamation me-3 informative-icon"
                  ></i>
                  <span
                    ><span class="text-center btn-danger">ADVERTENCIA!</span
                    >Aquí Aparecerán Las Opciones Una Vez Sea Validado Y Creado
                    El Bono</span
                  >
                </div>
                } @else {
                <div class="" (click)="onRowSelected(item)">
                  @if (verifyButtonAllowed) {
                  <div
                    class="d-flex align-items-center mb-2"
                    (click)="viewDetailsBonusCode()"
                  >
                    <i class="fas fa-check-double me-3 informative-icon"></i>
                    <span>Validar Información Del Bono</span>
                  </div>
                  } @if (deleteButtonAllowed) {
                  <div
                    class="d-flex align-items-center mb-2"
                    (click)="deleteBonusCode()"
                  >
                    <i class="fas fa-trash-alt me-3 informative-icon"></i>
                    <span>Eliminar Bono</span>
                  </div>
                  } @if (sendWsButtonAllowed) {
                  <a
                    target="_blank"
                    href="https://wa.me/?text=Saludos%2C%20tienes%20un%20bono%20pendiente%20por%20activar%20de%20{{
                      item.monto
                    }}%2C%20utiliza%20el%20código%3A%20*{{
                      item.codigo_promocional
                    }}*"
                  >
                    <div
                      class="d-flex align-items-center mb-2"
                      (click)="editStateBonusCode()"
                    >
                      <i class="fab fa-whatsapp me-3 informative-icon"></i>
                      <span>Enviar código promocional Vía Whatsapp</span>
                    </div>
                  </a>
                  } @if (copyButtonAllowed) {
                  <div
                    class="d-flex align-items-center mb-2"
                    (click)="
                      copyPromotionalCode(item.monto, item.codigo_promocional)
                    "
                  >
                    <i class="fa-solid fa-copy me-3 informative-icon"></i>
                    <span>Copiar Código De Bono</span>
                  </div>
                  }
                </div>
                }
              </li>
              <li style="border-top: 1px solid var(--chart-border)">
                <div class="d-flex align-items-center" (click)="closeMenu()">
                  <i class="fa-solid fa-copy me-3 informative-icon"></i>
                  <span> Cerrar</span>
                </div>
              </li>
            </ul>
          </div>
          }
        </div>
        } }
      </div>
      }
      <div class="card-footer">
        <shared-custom-pagination
          [collectionSize]="collectionSize"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onLimitChange($event)"
        ></shared-custom-pagination>
      </div>
      @if(showMenu || showSubMenu){
      <div class="overlay" (click)="closeMenu()"></div>
      } @if(showSubMenu){
      <div class="popover1">
        <div class="submenu">
          <ul>
            <li (click)="closeSubMenu()">Regresar</li>
          </ul>
          <div class="submenu-content">
            @switch (typeof(subMenuItem)) { @case ('object') { @for (column of
            columnsTable; track $index) {
            <div class="row" style="max-width: 98%">
              <div class="col-6 ellipsis-text" style="font-weight: bold">
                <p>{{ column.name }}:</p>
              </div>
              <div class="col-6 ellipsis-text">
                {{ subMenuItem[column.value] }}
              </div>
            </div>
            } } }
          </div>
        </div>
      </div>
      }
    </div>
  </div>
</div>
