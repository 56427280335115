<div class="container-fluid">
  <div *ngIf="isLoading" class="loader-box">
    <div class="loader-16"></div>
  </div>
  <div *ngIf="!isLoading">
    <form
      class="theme-form mega-form"
      [formGroup]="cwDivideForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="row d-flex align-items-center mb-3">
        <div class="col">
          <label class="col-form-label">Monto Disponible</label>
          <input
            type="text"
            class="form-control custom-input"
            readonly
            [value]="this.amountAvailable"
            placeholder="Input 1"
          />
        </div>
        <div class="col">
          <label class="col-form-label">Monto Restante</label>
          <input
            type="text"
            class="form-control custom-input"
            [value]="this.amountRemaining"
            readonly
            placeholder="Input 2"
          />
        </div>
        <div class="col-auto" style="margin-top: 7%;">
          <button
            type="button"
            class="btn btn-sm btn-add"
            (click)="addDivision()"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <div formArrayName="divisions" class="mt-3">
        @for (gastoGroup of divisionFormArray.controls; track $index) {
        <div [formGroupName]="$index" class="mb-2">
          <div class="mb-2">
            <label class="col-form-label">Monto {{ $index + 1 }}</label>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col">
              <input
                type="text"
                formControlName="cantidad"
                class="form-control custom-input"
                placeholder="Cantidad {{ $index + 1 }}"
                (input)="onDivisionInputChange()"
              />
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                (click)="deleteDivision($index)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        }
      </div>
      <hr class="mt-4 mb-4" />
      <div class="text-end">
        @if(isLoadingButton) {
        <button class="btn btn-divide btn-sm" type="submit" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
        } @else {
        <button type="submit" class="btn btn-divide btn-sm" [disabled]="isSubmitDisabled">Dividir</button>
        }
      </div>
    </form>
  </div>
</div>
