// TABLE
export const columnsTableLw = [
  {
    name: 'Acciones',
    isVisible: true,
  },
  {
    name: 'Estado',
    value: 'id_estado',
    isVisible: true,
  },
  {
    name: 'Fecha',
    value: 'fecha',
    isVisible: true,
  },
  {
    name: 'Monto',
    value: 'monto',
    isVisible: true,
  },
  {
    name: 'Categoría',
    value: 'id_categoria',
    isVisible: true,
  },
  {
    name: 'Titular',
    value: 'titular',
    isVisible: true,
  },
  {
    name: 'Agente',
    value: 'agente',
    isVisible: true,
  },
  {
    name: 'Comentario',
    isVisible: true,
  },
];

//ESTADOS
interface StateClass {
  text: string;
  classes: { [key: string]: boolean };
}

export const STATE_LW_CLASSES: { [key: number]: StateClass } = {
  1: { text: 'Pendiente', classes: { 'bg-pending': true } },
  2: { text: 'Procesado', classes: { 'bg-processing': true } },
  3: { text: 'Bono', classes: { 'bg-processing-validate': true } },
  4: { text: 'Comision', classes: { 'bg-reversal': true } },
  5: { text: 'Abono a<br>deuda', classes: { 'bg-divide': true } },
  6: { text: 'Adelanto de<br>Comision', classes: { 'bg-pending': true } },
  7: { text: 'Carga Doble', classes: { 'bg-processing': true } },
  8: { text: 'Auspicios', classes: { 'bg-processing-validate': true } },
};

export const MOVEMENT_TYPE = [
  { value: '1', label: 'Deposito' },
  { value: '2', label: 'Retiro' },
];

export const MOVEMENT_CLASSES: { [key: number]: string } = {
  1: 'bg-positive',
  2: 'bg-negative',
};

export const MOVEMENT_ICON_CLASSES: { [key: number]: string } = {
  1: 'fas fa-credit-card',
  2: 'fas fa-money-bill',
};
