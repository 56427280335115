import { EventEmitter, Injectable, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModalComponent } from '../../shared/components/modals/forms-modal/forms-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicModalService {
  private modalData: any = null;
  modalClosed = new EventEmitter<void>();

  constructor(private modalService: NgbModal) { }
  openModal(
    component: Type<any>,
    title: string,
    size?: string,
    position?: boolean,
    args?: any,
  ) {
    this.modalData = args;
    const modalRef = this.modalService.open(FormsModalComponent, {
      centered: position,
      size: size || 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.result.then(
      () => {
        this.modalClosed.emit();
      },
      () => { }
    );
    modalRef.componentInstance.component = component;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.args = args;
    return modalRef;
  }

  getModalData() {
    return this.modalData;
  }

  clearModalData() {
    this.modalData = null;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
