export const ROLES: { [key: string]: number } = {
  UNDEFINED: 0,
  PERSONAL_SERVICE: 1,
  PAYMENTS: 2,
  MASTER: 3,
  AGENT: 4,
  AFFILIATE: 5,
  MANAGEMENT: 7,
  SERVICE_BOSS: 8,
  PAYMENTS_ACCOUNTING: 9,
  ACCOUNTING: 10,
  PAYMENTS_BOSS: 11,
  RISK: 12,
  HOLDERS: 13,
};

export const USER_TYPES: { [key: string]: string } = {
  MASTER: '0',
  ADMINISTRATOR: '1',
  TRANSACCTIONS: '2',
  AGENT_LEVEL_ONE: '3',
  AGENT_LEVEL_TWO: '4',
  AGENT_LEVEL_THREE: '5',
};

export const MAIN_PERMISSIONS: { [key: string]: number } = {
  'Cargas y Retiros': 1,
  'Abonos y Pagos': 2,
  Reporte: 3,
  Contabilidad: 4,
  Auditoria: 5,
  Perfil: 6,
  Dashboard: 7,
  Usuarios: 8,
  Facturacion: 9,
  Bonos: 10,
  Marketing: 11,
  Bot: 12,
  'Roles y Permisos': 13,
  Deudas: 14,
  Balance: 15,
  Debt: 16,
  RRHH: 51,
};

export const SUB_PERMISSIONS: { [key: string]: number } = {
  Sub_Modulo_Agentes: 39,
  Sub_Modulo_Titulares: 40,
  Sub_Modulo_Administrativos: 41,
  Sub_Modulo_Financiero: 42,
  Sub_Modulo_Lista_CR: 43,
  Sub_Modulo_Lista_AP: 44,
  Sub_Modulo_Transaccion_Banco: 45,
  Sub_Modulo_Solicitar_CR: 46,
  Sub_Modulo_Cierre: 47,
  Sub_Modulo_Diario: 48,
  Sub_Modulo_Bot: 49,
  Sub_Modulo_Bono_Saldos: 50,
  Sub_Modulo_Codigo_Bonos: 51,
  Sub_Modulo_Bancario: 52,
  Sub_Modulo_Lista_Deuda: 54,
  Sub_Consultar_Modulos_Permisos: 53,
  Set_Password_Agent: 68,
  Block_Agents: 69,
  Sub_Modulo_Roles: 56,
  Sub_Modulo_Jugadores: 83,
  Sub_Modulo_Cuentas: 84,
  Sub_Modulo_Nominas: 92,
  Sub_Modulo_PageBalance: 78,
  Sub_Modulo_Permisos_Empleados: 94,
  Sub_Modulo_Bancario_Reporte_Bancario: 109,
  Sub_Modulo_Bancario_Cuentas_Bancarias: 110,
  Sub_Modulo_Bancario_Bancos: 111,
};
